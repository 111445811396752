/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  setData,
  getValue,
  enable,
  disable,
  show,
  hide,
  goTo,
  clearValue,
  hideColumn,
  setGridValue,
  getData,
  getSelectedRowNumber,
  getGridValue,
  
} from "../../shared/WindowImports";

import "./WhouseApplications.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import CommonContext from "../../Store/CommonContext";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { DateTime } from "../../widgets/common/DateTime";
// import { getData } from "../../Common/ApiService";
import Loading from "../../../Loader/Loading";
import { isEnabled, isVisible, showColumn } from "../../shared/ParentWindow";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import InterestAgreementSetup from "../../SystemMaintenanceMasterManagement/InterestAgreementSetup/InterestAgreementSetup";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WhouseApplications(props) {
  const commonctx = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let cropYear = JSON.parse(sessionStorage.getItem("year"));
  let compId = sessionStorage.getItem("compId");
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WhouseApplications",
    windowName: "WhouseApplications",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WhouseApplications",
    // START_USER_CODE-USER_WhouseApplications_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Warehouse Receipt Contract Applications",
      scrCode: "PN1090F",
    },
    // END_USER_CODE-USER_WhouseApplications_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnClearValues: {
      name: "btnClearValues",
      type: "ButtonWidget",
      parent: "grpbxContractApplication",
      Label: "Clear Values",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClearValues_PROPERTIES

      // END_USER_CODE-USER_btnClearValues_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    col1SGA: {
      name: "col1SGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1SGA_PROPERTIES

      // END_USER_CODE-USER_col1SGA_PROPERTIES
    },
    colApplied: {
      name: "colApplied",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplied_PROPERTIES

      // END_USER_CODE-USER_colApplied_PROPERTIES
    },
    colAppliedSGA: {
      name: "colAppliedSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Applied ",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedSGA_PROPERTIES

      // END_USER_CODE-USER_colAppliedSGA_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Buy Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colBuyPtSGA: {
      name: "colBuyPtSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Buy Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPtSGA_PROPERTIES

      // END_USER_CODE-USER_colBuyPtSGA_PROPERTIES
    },
    colContractDate: {
      name: "colContractDate",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Contract Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractDate_PROPERTIES

      // END_USER_CODE-USER_colContractDate_PROPERTIES
    },
    colContractDateSGA: {
      name: "colContractDateSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Contract Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractDateSGA_PROPERTIES

      // END_USER_CODE-USER_colContractDateSGA_PROPERTIES
    },
    colContractLbs: {
      name: "colContractLbs",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractLbs_PROPERTIES

      // END_USER_CODE-USER_colContractLbs_PROPERTIES
    },
    colContractLbsSGA: {
      name: "colContractLbsSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractLbsSGA_PROPERTIES

      // END_USER_CODE-USER_colContractLbsSGA_PROPERTIES
    },
    colContractNumber: {
      name: "colContractNumber",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Contract Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractNumber_PROPERTIES

      // END_USER_CODE-USER_colContractNumber_PROPERTIES
    },
    colContractNumberSGA: {
      name: "colContractNumberSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Contract Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractNumberSGA_PROPERTIES

      // END_USER_CODE-USER_colContractNumberSGA_PROPERTIES
    },
    colContType: {
      name: "colContType",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Cont Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContType_PROPERTIES

      // END_USER_CODE-USER_colContType_PROPERTIES
    },
    colContTypeSGA: {
      name: "colContTypeSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Cont Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContTypeSGA_PROPERTIES

      // END_USER_CODE-USER_colContTypeSGA_PROPERTIES
    },
    colDelvEndDt: {
      name: "colDelvEndDt",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Delivery End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvEndDt_PROPERTIES

      // END_USER_CODE-USER_colDelvEndDt_PROPERTIES
    },
    colDelvStartDt: {
      name: "colDelvStartDt",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Delivery Start Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvStartDt_PROPERTIES

      // END_USER_CODE-USER_colDelvStartDt_PROPERTIES
    },
    colGrouping: {
      name: "colGrouping",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Grouping",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrouping_PROPERTIES

      // END_USER_CODE-USER_colGrouping_PROPERTIES
    },
    colGrpShareLbs: {
      name: "colGrpShareLbs",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Grp Share Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpShareLbs_PROPERTIES

      // END_USER_CODE-USER_colGrpShareLbs_PROPERTIES
    },
    colGrpSharePct: {
      name: "colGrpSharePct",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Grp Share %",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpSharePct_PROPERTIES

      // END_USER_CODE-USER_colGrpSharePct_PROPERTIES
    },
    colLbsToApply: {
      name: "colLbsToApply",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLbsToApply_PROPERTIES

      // END_USER_CODE-USER_colLbsToApply_PROPERTIES
    },
    colOpenBalance: {
      name: "colOpenBalance",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalance_PROPERTIES

      // END_USER_CODE-USER_colOpenBalance_PROPERTIES
    },
    colOpenBalance1SGA: {
      name: "colOpenBalance1SGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalance1SGA_PROPERTIES

      // END_USER_CODE-USER_colOpenBalance1SGA_PROPERTIES
    },
    colOpenBalance2SGA: {
      name: "colOpenBalance2SGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalance2SGA_PROPERTIES

      // END_USER_CODE-USER_colOpenBalance2SGA_PROPERTIES
    },
    colOrder: {
      name: "colOrder",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Order",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrder_PROPERTIES

      // END_USER_CODE-USER_colOrder_PROPERTIES
    },
    colOrderSGA: {
      name: "colOrderSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Order",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrderSGA_PROPERTIES

      // END_USER_CODE-USER_colOrderSGA_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colPeanutVarietySGA: {
      name: "colPeanutVarietySGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVarietySGA_PROPERTIES

      // END_USER_CODE-USER_colPeanutVarietySGA_PROPERTIES
    },
    colPoundsToApplySGA: {
      name: "colPoundsToApplySGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApplySGA_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApplySGA_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPriceSGA: {
      name: "colPriceSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceSGA_PROPERTIES

      // END_USER_CODE-USER_colPriceSGA_PROPERTIES
    },
    colSeed: {
      name: "colSeed",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed_PROPERTIES

      // END_USER_CODE-USER_colSeed_PROPERTIES
    },
    colFOrB: {
      name: "colFOrB",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "F-B",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFOrB_PROPERTIES

      // END_USER_CODE-USER_colFOrB_PROPERTIES
    },
    colSeedCont: {
      name: "colSeedCont",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Seed Cont.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedCont_PROPERTIES

      // END_USER_CODE-USER_colSeedCont_PROPERTIES
    },
    colSeedOpen: {
      name: "colSeedOpen",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Seed Open",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedOpen_PROPERTIES

      // END_USER_CODE-USER_colSeedOpen_PROPERTIES
    },
    colSeedSGA: {
      name: "colSeedSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedSGA_PROPERTIES

      // END_USER_CODE-USER_colSeedSGA_PROPERTIES
    },
    colSeedTBD: {
      name: "colSeedTBD",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Seed TBD",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedTBD_PROPERTIES

      // END_USER_CODE-USER_colSeedTBD_PROPERTIES
    },
    colSeedTBDSGA: {
      name: "colSeedTBDSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Seed TBD",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedTBDSGA_PROPERTIES

      // END_USER_CODE-USER_colSeedTBDSGA_PROPERTIES
    },
    colTentApplied: {
      name: "colTentApplied",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Tentative Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentApplied_PROPERTIES

      // END_USER_CODE-USER_colTentApplied_PROPERTIES
    },
    colTentAppliedSGA: {
      name: "colTentAppliedSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Tentative Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentAppliedSGA_PROPERTIES

      // END_USER_CODE-USER_colTentAppliedSGA_PROPERTIES
    },
    colTotalApplication: {
      name: "colTotalApplication",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Total Application",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalApplication_PROPERTIES

      // END_USER_CODE-USER_colTotalApplication_PROPERTIES
    },
    colTotalApplicationSGA: {
      name: "colTotalApplicationSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Total Application",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalApplicationSGA_PROPERTIES

      // END_USER_CODE-USER_colTotalApplicationSGA_PROPERTIES
    },
    gridContractApplications: {
      name: "gridContractApplications",
      type: "GridWidget",
      parent: "grpbxContractApplication",
      gridCellsOrder:
        "colIsSts,btncol1,txtcolGrouping,txtcolBuyPt,txtcolContractNumber,txtcolContractDate,txtcolPeanutVariety,txtcolSeed,txtcolFOrB,txtcolOrder,txtcolLbsToApply,txtcolGrpSharePct,txtcolGrpShareLbs,txtcolContType,txtcolDelvStartDt,txtcolDelvEndDt,txtcolContractLbs,txtcolApplied,txtcolTentApplied,txtcolTotalApplication,txtcolOpenBalance,txtcolPrice,txtcolSeedCont,txtcolSeedOpen,txtcolSeedTBD",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridContractApplications_PROPERTIES
      isCobolTable: true,
      isEditable: true,
      needGridChange: true,
      toggleRender: true,
      conditionalRowStyles: [
          {
            when: (row) => { return row.txtcolOrder == '' },
            style: {
              
              input:{
                pointerEvents: "none",
                boxShadow:'none',
                border:'none',
                background:'transparent'
              }
            }
          }
        ],
      // END_USER_CODE-USER_gridContractApplications_PROPERTIES
    },
    gridSeedGrowerApplications: {
      name: "gridSeedGrowerApplications",
      type: "GridWidget",
      parent: "grpbxSeedGroverApplications",
      gridCellsOrder:
        "btncol2,txtcol1SGA,txtcolBuyPtSGA,txtcolContractNumberSGA,txtcolContractDateSGA,txtcolPeanutVarietySGA,txtcolSeedSGA,txtcolOrderSGA,txtcolPoundsToApplySGA,txtcolContTypeSGA,txtcolContractLbsSGA,txtcolAppliedSGA,txtcolOpenBalance1SGA,txtcolTentAppliedSGA,txtcoltotalApplicationSGA,txtcolOpenBalance2SGA,txtcolPriceSGA,txtcolSeedTBDSGA",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSeedGrowerApplications_PROPERTIES

      // END_USER_CODE-USER_gridSeedGrowerApplications_PROPERTIES
    },
    grpbxContractApplication: {
      name: "grpbxContractApplication",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseApplications",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxContractApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxContractApplication_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseApplications",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxSeedGroverApplications: {
      name: "grpbxSeedGroverApplications",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseApplications",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSeedGroverApplications_PROPERTIES

      // END_USER_CODE-USER_grpbxSeedGroverApplications_PROPERTIES
    },
    grpbxSpotApplication: {
      name: "grpbxSpotApplication",
      type: "GroupBoxWidget",
      parent: "grpbxWhouseApplications",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSpotApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxSpotApplication_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblContractApplications: {
      name: "lblContractApplications",
      type: "LabelWidget",
      parent: "grpbxContractApplication",
      Label: "Contract Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContractApplications_PROPERTIES

      // END_USER_CODE-USER_lblContractApplications_PROPERTIES
    },
    lblSeedGrowerApplications: {
      name: "lblSeedGrowerApplications",
      type: "LabelWidget",
      parent: "grpbxSeedGroverApplications",
      Label: "Seed Grower Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeedGrowerApplications_PROPERTIES

      // END_USER_CODE-USER_lblSeedGrowerApplications_PROPERTIES
    },
    lblSpotApplication: {
      name: "lblSpotApplication",
      type: "LabelWidget",
      parent: "grpbxSpotApplication",
      Label: "Spot Application",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpotApplication_PROPERTIES

      // END_USER_CODE-USER_lblSpotApplication_PROPERTIES
    },
    txtcol1SGA: {
      name: "txtcol1SGA",
      type: "TextBoxWidget",
      colName: "col1SGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1SGA_PROPERTIES

      // END_USER_CODE-USER_txtcol1SGA_PROPERTIES
    },
    txtcolApplied: {
      name: "txtcolApplied",
      type: "TextBoxWidget",
      colName: "colApplied",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolApplied_PROPERTIES

      // END_USER_CODE-USER_txtcolApplied_PROPERTIES
    },
    txtcolAppliedSGA: {
      name: "txtcolAppliedSGA",
      type: "TextBoxWidget",
      colName: "colAppliedSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedSGA_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES
      Enabled: false,
      ReadOnly: true,

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES
      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES
      // END_USER_CODE-USER_colSts_PROPERTIES
    },

    txtcolBuyPtSGA: {
      name: "txtcolBuyPtSGA",
      type: "TextBoxWidget",
      colName: "colBuyPtSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPtSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPtSGA_PROPERTIES
    },
    txtcolContractDate: {
      name: "txtcolContractDate",
      type: "TextBoxWidget",
      colName: "colContractDate",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractDate_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolContractDate_PROPERTIES
    },
    txtcolContractDateSGA: {
      name: "txtcolContractDateSGA",
      type: "TextBoxWidget",
      colName: "colContractDateSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractDateSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractDateSGA_PROPERTIES
    },
    txtcolContractLbs: {
      name: "txtcolContractLbs",
      type: "TextBoxWidget",
      colName: "colContractLbs",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolContractLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolContractLbs_PROPERTIES
    },
    txtcolContractLbsSGA: {
      name: "txtcolContractLbsSGA",
      type: "TextBoxWidget",
      colName: "colContractLbsSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractLbsSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractLbsSGA_PROPERTIES
    },
    txtcolContractNumber: {
      name: "txtcolContractNumber",
      type: "TextBoxWidget",
      colName: "colContractNumber",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractNumber_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolContractNumber_PROPERTIES
    },
    txtcolContractNumberSGA: {
      name: "txtcolContractNumberSGA",
      type: "TextBoxWidget",
      colName: "colContractNumberSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractNumberSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractNumberSGA_PROPERTIES
    },
    txtcolContType: {
      name: "txtcolContType",
      type: "TextBoxWidget",
      colName: "colContType",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolContType_PROPERTIES

      // END_USER_CODE-USER_txtcolContType_PROPERTIES
    },
    txtcolContTypeSGA: {
      name: "txtcolContTypeSGA",
      type: "TextBoxWidget",
      colName: "colContTypeSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContTypeSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContTypeSGA_PROPERTIES
    },
    txtcolDelvEndDt: {
      name: "txtcolDelvEndDt",
      type: "TextBoxWidget",
      colName: "colDelvEndDt",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolDelvEndDt_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvEndDt_PROPERTIES
    },
    txtcolDelvStartDt: {
      name: "txtcolDelvStartDt",
      type: "TextBoxWidget",
      colName: "colDelvStartDt",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolDelvStartDt_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvStartDt_PROPERTIES
    },
    txtcolGrouping: {
      name: "txtcolGrouping",
      type: "TextBoxWidget",
      colName: "colGrouping",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrouping_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolGrouping_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridContractApplications",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    btncol2: {
      name: "btncol2",
      type: "ButtonWidget",
      colName: "col2",
      parent: "gridSeedGrowerApplications",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol2_PROPERTIES

      // END_USER_CODE-USER_btncol2_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    txtcolGrpShareLbs: {
      name: "txtcolGrpShareLbs",
      type: "TextBoxWidget",
      colName: "colGrpShareLbs",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolGrpShareLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpShareLbs_PROPERTIES
    },
    txtcolGrpSharePct: {
      name: "txtcolGrpSharePct",
      type: "TextBoxWidget",
      colName: "colGrpSharePct",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolGrpSharePct_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpSharePct_PROPERTIES
    },
    txtcolLbsToApply: {
      name: "txtcolLbsToApply",
      type: "TextBoxWidget",
      colName: "colLbsToApply",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolLbsToApply_PROPERTIES
    },
    txtcolOpenBalance: {
      name: "txtcolOpenBalance",
      type: "TextBoxWidget",
      colName: "colOpenBalance",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolOpenBalance_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalance_PROPERTIES
    },
    txtcolOpenBalance1SGA: {
      name: "txtcolOpenBalance1SGA",
      type: "TextBoxWidget",
      colName: "colOpenBalance1SGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenBalance1SGA_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalance1SGA_PROPERTIES
    },
    txtcolOpenBalance2SGA: {
      name: "txtcolOpenBalance2SGA",
      type: "TextBoxWidget",
      colName: "colOpenBalance2SGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenBalance2SGA_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalance2SGA_PROPERTIES
    },
    txtcolOrder: {
      name: "txtcolOrder",
      type: "TextBoxWidget",
      colName: "colOrder",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrder_PROPERTIES

      // END_USER_CODE-USER_txtcolOrder_PROPERTIES
    },
    txtcolOrderSGA: {
      name: "txtcolOrderSGA",
      type: "TextBoxWidget",
      colName: "colOrderSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrderSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolOrderSGA_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolPeanutVarietySGA: {
      name: "txtcolPeanutVarietySGA",
      type: "TextBoxWidget",
      colName: "colPeanutVarietySGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVarietySGA_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVarietySGA_PROPERTIES
    },
    txtcolPoundsToApplySGA: {
      name: "txtcolPoundsToApplySGA",
      type: "TextBoxWidget",
      colName: "colPoundsToApplySGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPoundsToApplySGA_PROPERTIES

      // END_USER_CODE-USER_txtcolPoundsToApplySGA_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPriceSGA: {
      name: "txtcolPriceSGA",
      type: "TextBoxWidget",
      colName: "colPriceSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceSGA_PROPERTIES
    },
    txtcolSeed: {
      name: "txtcolSeed",
      type: "TextBoxWidget",
      colName: "colSeed",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeed_PROPERTIES
    },
    txtcolFOrB: {
      name: "txtcolFOrB",
      type: "TextBoxWidget",
      colName: "colFOrB",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolFOrB_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolFOrB_PROPERTIES
    },

    txtcolSeedCont: {
      name: "txtcolSeedCont",
      type: "TextBoxWidget",
      colName: "colSeedCont",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolSeedCont_PROPERTIES
      // END_USER_CODE-USER_txtcolSeedCont_PROPERTIES
    },
    txtcolSeedOpen: {
      name: "txtcolSeedOpen",
      type: "TextBoxWidget",
      colName: "colSeedOpen",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedOpen_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeedOpen_PROPERTIES
    },
    txtcolSeedSGA: {
      name: "txtcolSeedSGA",
      type: "TextBoxWidget",
      colName: "colSeedSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedSGA_PROPERTIES
    },
    txtcolSeedTBD: {
      name: "txtcolSeedTBD",
      type: "TextBoxWidget",
      colName: "colSeedTBD",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedTBD_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolSeedTBD_PROPERTIES
    },
    txtcolSeedTBDSGA: {
      name: "txtcolSeedTBDSGA",
      type: "TextBoxWidget",
      colName: "colSeedTBDSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedTBDSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedTBDSGA_PROPERTIES
    },
    txtcolTentApplied: {
      name: "txtcolTentApplied",
      type: "TextBoxWidget",
      colName: "colTentApplied",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolTentApplied_PROPERTIES

      // END_USER_CODE-USER_txtcolTentApplied_PROPERTIES
    },
    txtcolTentAppliedSGA: {
      name: "txtcolTentAppliedSGA",
      type: "TextBoxWidget",
      colName: "colTentAppliedSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTentAppliedSGA_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolTentAppliedSGA_PROPERTIES
    },
    txtcolTotalApplication: {
      name: "txtcolTotalApplication",
      type: "TextBoxWidget",
      colName: "colTotalApplication",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotalApplication_PROPERTIES
      Enabled: false,
      ReadOnly: true,
      // END_USER_CODE-USER_txtcolTotalApplication_PROPERTIES
    },
    txtcoltotalApplicationSGA: {
      name: "txtcoltotalApplicationSGA",
      type: "TextBoxWidget",
      colName: "colTotalApplicationSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcoltotalApplicationSGA_PROPERTIES

      // END_USER_CODE-USER_txtcoltotalApplicationSGA_PROPERTIES
    },
    txtLbsToApply: {
      name: "txtLbsToApply",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Lbs to Apply:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtLbsToApply_PROPERTIES
    },
    txtObligationLocation: {
      name: "txtObligationLocation",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Obligation Location",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtObligationLocation_PROPERTIES

      // END_USER_CODE-USER_txtObligationLocation_PROPERTIES
    },
    txtOrder: {
      name: "txtOrder",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Order:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrder_PROPERTIES

      // END_USER_CODE-USER_txtOrder_PROPERTIES
    },
    txtOverideFarmNum: {
      name: "txtOverideFarmNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Overide Farm #",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverideFarmNum_PROPERTIES

      // END_USER_CODE-USER_txtOverideFarmNum_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPounds: {
      name: "txtPounds",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds_PROPERTIES

      // END_USER_CODE-USER_txtPounds_PROPERTIES
    },
    txtPrice: {
      name: "txtPrice",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrice_PROPERTIES

      // END_USER_CODE-USER_txtPrice_PROPERTIES
    },
    txtSeedLbs: {
      name: "txtSeedLbs",
      type: "TextBoxWidget",
      parent: "grpbxSeedGroverApplications",
      Label: "Seed Pounds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedLbs_PROPERTIES

      // END_USER_CODE-USER_txtSeedLbs_PROPERTIES
    },
    txtUnloadLocation: {
      name: "txtUnloadLocation",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Unload Location",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUnloadLocation_PROPERTIES

      // END_USER_CODE-USER_txtUnloadLocation_PROPERTIES
    },
    txtWarehouseReceiptNum: {
      name: "txtWarehouseReceiptNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Warehouse Receipt #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceiptNum_PROPERTIES
    },
    grpbxWhouseApplications: {
      name: "grpbxWhouseApplications",
      type: "GroupBoxWidget",
      parent: "WhouseApplications",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWhouseApplications_PROPERTIES

      // END_USER_CODE-USER_grpbxWhouseApplications_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "WhouseApplications",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const [rowNo, setRowNo] = useState()

  const [myArr, setMyArr] = useState([]);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj;
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    setData(thisObj, "frmWhouseApplicationOKFlag", null);
    setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', { flag : true })
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  let sSeg;
  let rcpt_status = "";
  let mbTentApplExist = "";
  let dtIssueDate = "";
  let mdtWRDate = "";
  let dtEndDate = "";
  let mbtent_cont_appl_ind = "";
  let mbUnappliedSeedContAvail = false;
  let bFilling = true;
  let sContractDate;
  let sBuyPoint;
  let sContractNum;
  let sContractType;
  let sGroupingNum;
  let sHoldGrpNum;
  let cAcol7 = "";
  let sContract;

  const user_id = sessionStorage.getItem("userid");

  //Function for FormLoad
  async function FormLoad() {
    try {
      setLoading(true);
      let lblOverrideFarm = "";
      let lblDMAVendorText = "";
      let lblDMAVendorValue = "";
      let data13 = getData(thisObj, "frmWhouseApplications");
      let lblVarietyValue = data13.lblVarietyTag;
      let Mode = data13.Mode;
      let lblBuyPtIdValue = data13.lblBuyPtIdValue;
      let lblBuyPtIdText = data13.lblBuyPtIdText;
      let lblUnloadBuyPtIdValue = data13.lblUnloadBuyPtIdValue;
      let lblUnloadBuyPtIdText = data13.lblUnloadBuyPtIdText;
      let lblWRNumberValue = data13.lblWRNumberValue;
      let lblVendorValue = data13?.vendor;

      setValue(thisObj, "txtWarehouseReceiptNum", lblWRNumberValue);
      setData(thisObj, "lblWRNumberValue", lblWRNumberValue);
      setValue(thisObj, "txtUnloadLocation", lblUnloadBuyPtIdText);
      setValue(thisObj, "txtObligationLocation", lblBuyPtIdText);
      setValue(thisObj, "txtOverideFarmNum", lblOverrideFarm);
      setData(thisObj, "lblBuyPtIdValue", lblBuyPtIdValue);
      setData(thisObj, "lblUnloadBuyPtIdValue", lblUnloadBuyPtIdValue);
      setData(thisObj, "lblVendorValue", lblVendorValue);
      setData(thisObj, "Mode", Mode);
      setData(thisObj, "lblVarietyValue", lblVarietyValue);
      setData(thisObj, "lblDMAVendorText", lblDMAVendorText);
      setData(thisObj, "lblDMAVendorValue", lblDMAVendorValue);
      setData(thisObj, "lblVendorValue", lblVendorValue);
      setData(thisObj, "lblOverrideFarm", lblOverrideFarm);

      await InitContractApplicationsGrid();
      await InitSeedGrowerApplicationsGrid();
      switch (Mode) {
        case "PURCHASE":
          await bFillContractApplications();
          hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
          hideColumn(thisObj, "gridContractApplications", "txtcolGrpSharePct");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
          break;
        case "TENTATIVE_APPLICATION":
          await bFillContractApplicationsTentative();
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
          break;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during Form Load");
      }
      return false;
    } finally {
      setLoading(false);
    }
  }

  //Code for bFillContractApplications
  const bFillContractApplications = async () => {
    let bFilling = true;
    let bFillContractApplications = false;
    let penutId = "";
    let lblOverrideFarm = getData(thisObj, "lblOverrideFarm");
    let lblBuyPtIdValue = getData(thisObj, "lblBuyPtIdValue");
    let lblWRNumberValue = getData(thisObj, "lblWRNumberValue");
    let lblUnloadBuyPtIdValue = getData(thisObj, "lblUnloadBuyPtIdValue");
    let lblVarietyValue = getData(thisObj, "lblVarietyValue");
    let Mode = getData(thisObj, "Mode");
    let lblDMAVendorText = getData(thisObj, "lblDMAVendorText");
    let lblDMAVendorValue = getData(thisObj, "lblDMAVendorValue");
    let lblVendorValue = getData(thisObj, "lblVendorValue");
    let response = await WarehouseReceiptService.RetrieveWareHouseReciptHeaderDetails(
      lblBuyPtIdValue,
      lblWRNumberValue,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (response.length > 0) {
      setValue(thisObj, "txtPeanutType", response[0].pnut_type_name);
      setData(thisObj, "PeanutTypeId", response[0].pnut_type_id);
      penutId = response[0].pnut_type_id;
      setData(thisObj, "lblSeed", response[0].unld_seed_load_found);
      sSeg = response[0].seg_type;
      rcpt_status = response[0].rcpt_status;
      setData(thisObj, "rcpt_status", rcpt_status);
      mbTentApplExist = response[0].tent_cont_appl_ind;

      switch (Mode) {
        case "PURCHASE":
          setValue(thisObj, "txtPounds", response[0].net_wt);
          setData(thisObj, "txtPounds", response[0].net_wt);
          break;
        case "DELVOUT":
          setValue(thisObj, "txtPounds", response[0].net_wt);
          setData(thisObj, "txtPounds", response[0].net_wt);
          break;
        default:
        // code block
      }
      dtIssueDate = response[0].issued_date;
      mdtWRDate = response[0].issued_date;
      setData(thisObj, "mdtWRDate", mdtWRDate);
      dtEndDate = response[0].chrg_end_date;
      if (dtEndDate == null || dtEndDate == "") {
        dtEndDate = moment(Date.now()).format("MM/DD/YYYY");
      }
      mbtent_cont_appl_ind = response[0].tent_cont_appl_ind;
      setData(thisObj, "mbtent_cont_appl_ind", mbtent_cont_appl_ind);
    } else {
      bFilling = false;
      return false;
    }

    let func_id = "PN1090";
    let func_sub_id = "";
    let farm_id = null;
    let pnut_type_id = response[0].pnut_type_id;
    let pnut_variety_id = lblVarietyValue;
    let whse_rcpt_num = lblWRNumberValue;
    let seed_grower = null;
    let seg_type = sSeg;
    let txtOverideFarmNum = getData(thisObj, "lblOverrideFarm");
    let data = null;
    let contApArr = [];
    let cAobj = {};
    let sGaArr = [];
    let sGaobj = {};

    if (lblDMAVendorText != "") {
      if (txtOverideFarmNum === "") {
        let rs = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          lblDMAVendorValue,
          farm_id,
          seed_grower,
          seg_type
        );
        if (rs.length > 0) {
          data = rs;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      } else {
        let rs1 = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          lblDMAVendorValue,
          txtOverideFarmNum,
          seed_grower,
          seg_type
        );
        if (rs1.length > 0) {
          data = rs1;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      }
    } else {
      if (txtOverideFarmNum === "") {
        let rs2 = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          lblVendorValue,
          farm_id,
          seed_grower,
          seg_type
        );
        if (rs2.length > 0) {
          data = rs2;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      } else {
        let rs3 = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          lblVendorValue,
          txtOverideFarmNum,
          seed_grower,
          seg_type
        );
        if (rs3.length > 0) {
          data = rs2;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      }
    }

    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        sContractDate = data[i].cont_date.slice(0, 9);
        sBuyPoint = data[i].buy_pt_id;
        sContractNum = data[i].contract_num;
        sContractType = data[i].cont_type;
        sGroupingNum = data[i].grouping_num;

        if (sContractType === "S") {
          if (
            Number(data[i].addendum_seed_grower) > 0 ||
            data[i].seed_tbd === "Y"
          ) {
            if (getData(thisObj, "lblSeed") == "Y") {
              //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 0, vsfDataSGA.Row, 8) = &HC0C0C0
              if (mbtent_cont_appl_ind == "O" || mbtent_cont_appl_ind == "I") {
                //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 9, vsfDataSGA.Row, 9) = &HC0C0C0
              } else {
                //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 9, vsfDataSGA.Row, 9) = &HFFFFFF
              }
              //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 10, vsfDataSGA.Row, 30) = &HC0C0C0

              if (
                Number(data[i].seed_grower_cont_lbs) >
                Number(data[i].seed_grower_appl_lbs)
              ) {
                mbUnappliedSeedContAvail = true;
              }
              sGaobj.txtcol1SGA = ""; //1
              sGaobj.txtcolBuyPtSGA = sBuyPoint; //2
              sGaobj.txtcolContractNumberSGA = sContractNum; //3
              sGaobj.txtcolContractDateSGA = sContractDate; //4
              let symblmark = "";
              switch (data[i].symbol_ind) {
                case "TRADEMARK":
                  symblmark = " " + " \u2122";
                  break;
                case "REGISTERED TRADEMARK":
                  symblmark = " " + " \xAE";
                  break;
                case "COPYRIGHT":
                  symblmark = " " + " \xA9";
                  break;
                default:
                // code block
              }
              sGaobj.txtcolPeanutVarietySGA =
                data[i].pnut_variety_name + symblmark; //5
              sGaobj.txtcolSeedSGA = data[i].seed_grower; //6
              sGaobj.txtcolOrderSGA = ""; //8
              sGaobj.txtcolPoundsToApplySGA = ""; //9
              sGaobj.txtcolContTypeSGA = sContractType; //12
              sGaobj.txtcolContractLbsSGA = data[i].seed_grower_cont_lbs; //15
              sGaobj.txtcolAppliedSGA = data[i].seed_grower_appl_lbs; //16
              sGaobj.txtcolOpenBalance1SGA =
                Number(data[i].seed_grower_cont_lbs) -
                Number(data[i].seed_grower_appl_lbs); //17
              sGaobj.txtcolTentAppliedSGA = ""; //18
              sGaobj.txtcoltotalApplicationSGA =
                Number(data[i].seed_grower_appl_lbs) + 0; //19
              sGaobj.txtcolOpenBalance2SGA =
                Number(data[i].seed_grower_cont_lbs) -
                Number([Number(data[i].seed_grower_appl_lbs) + 0]); //20
              sGaobj.txtcolPriceSGA = data[i].seed_grower_prem_per_ton; //21
              sGaobj.txtcolSeedTBDSGA = data[i].seed_tbd;
              sGaArr.push(sGaobj);
              sGaobj = {};
            }
          }
          // sContract =data[i].addendum_seed_grower
        } else {
          if (Number(data[i].contract_pricing) != 0) {
            // vsfData.Cell(flexcpBackColor, vsfData.Row, 0, vsfData.Row, 7) = &HC0C0C0
            // vsfData.Cell(flexcpBackColor, vsfData.Row, 8, vsfData.Row, 9) = &HFFFFFF
            // vsfData.Cell(flexcpBackColor, vsfData.Row, 10, vsfData.Row, 30) = &HC0C0C0
            if (Number(sGroupingNum) != "" && sHoldGrpNum != sGroupingNum)
              cAobj.txtcolGrouping = sGroupingNum; //1
            cAobj.txtcolOrder = ""; //8
            sHoldGrpNum = sGroupingNum;
          }
          if (sGroupingNum != "") {
            //vsfData.Cell(flexcpBackColor, vsfData.Row, 0, vsfData.Row, 30) = &HC0C0C0
          }
          cAobj.txtcolGrouping = "";
          cAobj.txtcolBuyPt = sBuyPoint; //2
          cAobj.txtcolContractNumber = sContractNum; //3
          cAobj.txtcolContractDate = sContractDate; //4
          let symbolmark = "";
          switch (data[i].symbol_ind) {
            case "TRADEMARK":
              symbolmark = " " + " \u2122";
              break;
            case "REGISTERED TRADEMARK":
              symbolmark = " " + " \xAE";
              break;
            case "COPYRIGHT":
              symbolmark = " " + " \xA9";
              break;
            default:
            // code block
          }
          cAobj.txtcolPeanutVariety = data[i].pnut_variety_name + symbolmark; //5

          if (data[i].seed_grower != "") {
            cAobj.txtcolSeed = data[i].seed_grower; //6
          } else {
            cAobj.txtcolSeed = data[i].seed_grower; //6
          }

          if (data[i].contract_pricings[0].firm_basis == "F") {
            cAcol7 = "Firm"; //7
            cAobj.txtcolFOrB = "Firm";
          } else {
            cAcol7 = "Basis";
            cAobj.txtcolFOrB = "Basis";
          }
          cAobj.txtcolOrder = ""; //8
          cAobj.txtcolLbsToApply = '' //9
          cAobj.txtcolGrpSharePct = data[i].grouping_share; //10
          cAobj.txtcolGrpShareLbs = ""; //11
          cAobj.txtcolContType = sContractType; //12
          cAobj.txtcolDelvStartDt = data[
            i
          ].contract_pricings[0].delv_beg_date.slice(0, 10); //13
          cAobj.txtcolDelvEndDt = data[
            i
          ].contract_pricings[0].delv_end_date.slice(0, 10); //14
          cAobj.txtcolContractLbs = data[i].contract_pricings[0].cont_lbs; //15
          cAobj.txtcolApplied = data[i].contract_pricings[0].appl_lbs; //16
          cAobj.txtcolSeedOpen =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number(data[i].contract_pricings[0].appl_lbs); //17
          cAobj.txtcolTentApplied = data[i].contract_pricings[0].tentative_lbs; //18
          cAobj.txtcolTotalApplication =
            Number(data[i].contract_pricings[0].appl_lbs) +
            Number(data[i].contract_pricings[0].tentative_lbs); //19
          cAobj.txtcolOpenBalance = response[0].net_wt; //20
          cAobj.txtcolPrice = data[i].contract_pricings[0].price_per_ton; //21
          cAobj.col22 = data[i].contract_pricings[0].appl_lbs; //22
          cAobj.col23 = data[i].contract_pricings[0].tentative_lbs; //23
          cAobj.col24 =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number(data[i].contract_pricings[0].appl_lbs); //24
          cAobj.col25 =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number(data[i].contract_pricings[0].appl_lbs) -
            Number(data[i].contract_pricings[0].tentative_lbs); //25
          cAobj.col26 = data[i].grouping_date_time; //26
          cAobj.txtcolSeedCont = data[i].seed_adden_cont_num; //28
          cAobj.txtcolSeedOpen =
            Number(data[i].seed_grower_cont_lbs) -
            Number(data[i].seed_grower_appl_lbs); //29
          cAobj.txtcolSeedTBD = data[i].seed_tbd;
          contApArr.push(cAobj);
          cAobj = {};
        }
      }

      let result = await WarehouseReceiptService.RetrieveWarehouseContractApplicationDetails(
        compId,
        cropYear,
        lblBuyPtIdValue,
        lblWRNumberValue
      );
      if (result.length > 0) {
        for (let j = 0; j < result.length; j++) {
          if (Number(result[j].pricing_line) === 1) {
            if (
              contApArr[j].txtcolContractNumber === result[j].contract_num &&
              cAcol7 == "Firm"
            ) {
              contApArr[j].txtcolApplied =
                Number(contApArr[j].txtcolApplied) -
                Number(result[j].appl_lbs_adj); //16
              contApArr[j].txtcolSeedOpen =
                contApArr[j].txtcolContractLbs - contApArr[j].txtcolApplied;
              contApArr[j].txtcolTotalApplication =
                contApArr[j].txtcolApplied + contApArr[j].txtcolTentApplied;
              contApArr[j].txtcolOpenBalance =
                contApArr[j].txtcolContractLbs -
                contApArr[j].txtcolTotalApplication;
              contApArr[j].txtcolTentApplied =
                Number(contApArr[j].txtcolTentApplied) -
                Number(result[j].tent_lbs_adj); //18
              contApArr[j].txtcolTotalApplication =
                contApArr[j].txtcolApplied + contApArr[j].txtcolTentApplied;
              contApArr[j].txtcolOpenBalance =
                contApArr[j].txtcolContractLbs -
                contApArr[j].txtcolTotalApplication;
              contApArr[j].txtcolTotalApplication =
                Number(contApArr[j].txtcolApplied) +
                Number(contApArr[j].txtcolTentApplied); //19
              contApArr[j].col22 =
                Number(contApArr[j].col22) - Number(result[j].appl_lbs_adj); //22
              contApArr[j].col23 =
                Number(contApArr[j].col24) - Number(result[j].tent_lbs_adj); //23
              contApArr[j].col24 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied); //24
              contApArr[j].col25 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied) -
                Number(contApArr[j].tentative_lbs); //25
            }
          } else {
            if (
              contApArr[j].txtcolContractNumber === result[j].contract_num &&
              cAcol7 == "Basis"
            ) {
              contApArr[j].txtcolApplied =
                Number(contApArr[j].txtcolApplied) -
                Number(result[j].appl_lbs_adj); //16
              contApArr[j].txtcolSeedOpen =
                contApArr[j].txtcolContractLbs - contApArr[j].txtcolApplied;
              contApArr[j].txtcolTotalApplication =
                contApArr[j].txtcolApplied + contApArr[j].txtcolTentApplied;
              contApArr[j].txtcolOpenBalance =
                contApArr[j].txtcolContractLbs -
                contApArr[j].txtcolTotalApplication;
              contApArr[j].txtcolTentApplied =
                Number(contApArr[j].txtcolTentApplied) -
                Number(result[j].tent_lbs_adj); //18
              contApArr[j].txtcolTotalApplication =
                contApArr[j].txtcolApplied + contApArr[j].txtcolTentApplied;
              contApArr[j].txtcolOpenBalance =
                contApArr[j].txtcolContractLbs -
                contApArr[j].txtcolTotalApplication;
              contApArr[j].txtcolTotalApplication =
                Number(contApArr[j].txtcolApplied) +
                Number(contApArr[j].txtcolTentApplied); //19
              contApArr[j].col22 =
                Number(contApArr[j].col22) - Number(result[j].appl_lbs_adj); //22
              contApArr[j].col23 =
                Number(contApArr[j].col23) - Number(result[j].tent_lbs_adj); //23
              contApArr[j].col24 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied); //24
              contApArr[j].col25 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied) -
                Number(contApArr[j].tentative_lbs); //25
            }
          }
        }
      }
      setValue(thisObj, "gridSeedGrowerApplications", sGaArr);
      setData(thisObj, "gridSeedGrowerApplications", sGaArr);
      setValue(thisObj, "gridContractApplications", contApArr);
    }
    setValue(thisObj, "txtLbsToApply", "");
    let pnut_type = penutId;
    let spotPricectrlrspns = await SettlementService.RetrieveSpotPriceControlList(
      compId,
      cropYear,
      lblUnloadBuyPtIdValue,
      pnut_type,
      dtEndDate
    );
    if (spotPricectrlrspns[0] != null) {
      switch (sSeg) {
        case "1":
          if (spotPricectrlrspns[0].seg_1_spot != "") {
            setValue(thisObj, "txtPrice", spotPricectrlrspns[0].seg_1_spot);
            setData(thisObj, "txtPrice", spotPricectrlrspns[0].seg_1_spot);
          } else {
            clearValue(thisObj, "txtPrice");
          }
          break;
        case "2":
          if (spotPricectrlrspns[0].seg_2_oil_spot != "") {
            setValue(thisObj, "txtPrice", spotPricectrlrspns[0].seg_2_oil_spot);
            setData(thisObj, "txtPrice", spotPricectrlrspns[0].seg_2_oil_spot);
          } else {
            clearValue(thisObj, "txtPrice");
          }
          break;
        case "3":
          if (spotPricectrlrspns[0].seg_3_oil_spot != "") {
            setValue(thisObj, "txtPrice", spotPricectrlrspns[0].seg_3_oil_spot);
            setData(thisObj, "txtPrice", spotPricectrlrspns[0].seg_3_oil_spot);
          } else {
            clearValue(thisObj, "txtPrice");
          }
          break;
        default:
      }
    }
  
    let data20 = getValue(thisObj, "txtPrice") || getData(thisObj, "txtPrice");
    if (data20 == "") {
      disable(thisObj, "txtLbsToApply");
      disable(thisObj, "txtOrder");
    } else if (data20 == "0.00") {
      disable(thisObj, "txtLbsToApply");
      disable(thisObj, "txtOrder");
    }
    if (!bFillPounds()) {
      bFillContractApplications = false;
      bFilling = false;
      hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
      hideColumn(thisObj, "gridContractApplications", "txtcolGrpSharePct");
      hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
      hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
      hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
      hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
      return;
    }
    let gridData = getValue(thisObj, "gridContractApplications");
    let data21 = gridData.length;
    if (
      getValue(thisObj, "txtLbsToApply") != "" &&
      getValue(thisObj, "txtLbsToApply") != "0" &&
      data21 == "1" &&
      getValue(thisObj, "txtLbsToApply") != "" &&
      isEnabled(thisObj, "txtLbsToApply") == true
    ) {
      let data22 = getValue(thisObj, "txtPounds");
      setValue(thisObj, "txtLbsToApply", data22);
    }
    bFillContractApplications = true;
    EnableDisableControls();
    if (
      [mbtent_cont_appl_ind === "O" || mbtent_cont_appl_ind === "I"] &&
      Mode === "PURCHASE"
    ) {
      let gridData = getValue(thisObj, "gridContractApplications");
      for (let i = 0; i < gridData.length; i++) {
        if (isNaN(gridData[i].txtcolGrouping) == true) {
          sHoldGrpNum = gridData[i].txtcolGrouping;
        }
        if (isNaN(gridData[i].txtcolGrpSharePct) == true) {
          gridData[i].txtcolGrouping = sHoldGrpNum;
        }
        disable(thisObj, "txtOrder");
        disable(thisObj, "txtLbsToApply");
      }
    } else {
      nOrder = 1;
      nPounds = getValue(thisObj, "txtPounds");
      bPoundsLeft = true;

      let gridData = getValue(thisObj, "gridContractApplications");
      for (let i = 0; i < gridData.length; i++) {
        if (isNaN(gridData[i].txtcolGrouping) == true) {
          if (isNaN(gridData[i].txtcolGrpSharePct) == true) {
            if (nPounds <= gridData[i].txtcolOpenBalance) {
              if (bPoundsLeft == "true") {
                gridData[i].txtcolOrder = nOrder;
                bPoundsLeft = false;
              }
            } else {
              if (gridData[i].txtcolOpenBalance > 0) {
                if (bPoundsLeft == "true") {
                  nPounds = nPounds - gridData[i].txtcolOpenBalance;
                  gridData[i].txtcolOrder = nOrder;
                  nOrder = nOrder + 1;
                }
              } else {
                //vsfData.Cell(flexcpBackColor, nListIndex, 8, nListIndex, 9) = &HC0C0C0
              }
            }
          } else {
            if (isNaN(gridData[i].txtcolGrpSharePct) == true) {
              nGrpPounds = nGrpPounds + gridData[i].txtcolOpenBalance;
            }
            if (nGrpPounds > 0) {
              if (nPounds <= nGrpPounds) {
                if (bPoundsLeft == "True") {
                  gridData[i].txtcolOrder = nOrder;
                  bPoundsLeft = false;
                }
              } else {
                if (bPoundsLeft == "True") {
                  nPounds = nPounds - nGrpPounds;
                  gridData[i].txtcolGrouping = nOrder;
                  nOrder = nOrder + 1;
                }
              }
            } else {
              //vsfData.Cell(flexcpBackColor, nGrpIndex, 8, nGrpIndex, 9) = &HC0C0C0
            }
          }
        } else {
          nGrpPounds = 0;
          nGrpIndex = nListIndex;
        }
      }
      for (let i = 0; i < gridData.length; i++) {
        gridData[i].col27 = gridData[i].txtcolLbsToApply;
      }
    }
    bFilling = false;
    return;
  };

  //Code for ontxtcolOrderBlur
  const ontxtcolOrderBlur =(e)=>{
    let vale1 = e.target.value
    let rowsData =
      getData(_kaledo.thisObj, "gridContractApplications") ||
      getValue(_kaledo.thisObj, "gridContractApplications");
      for(let i = 0;i<rowsData.length;i++){
        if(rowsData[i].txtcolOrder != ""){
        if(parseInt(rowsData[i].txtcolOrder) != e.target.value){
          rowsData[i].txtcolOrder = vale1;
              setValue(thisObj,'gridContractApplications',rowsData)
              _kaledo.thisObj.setState(current => ({
                  ...current,
                  gridContractApplications: {
                    ...current.gridContractApplications,
                    toggleRender: !state.gridContractApplications.toggleRender
                  }
                }))
                
                hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
                hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
                hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");     
        }
      }
      }
    ComputeOrderedValues()
    hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
    hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
    hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD"); 
  }
  thisObj.ontxtcolOrderBlur = ontxtcolOrderBlur;

  //Code for ontxtcolLbsToApplyBlur
  const ontxtcolLbsToApplyBlur =(e)=>{
    let vale = e.target.value;
    let rowsData =
      getData(_kaledo.thisObj, "gridContractApplications") ||
      getValue(_kaledo.thisObj, "gridContractApplications");
    for(let i = 0;i<rowsData.length;i++){
      if(rowsData[i].txtcolLbsToApply != ""){
      if(parseInt(rowsData[i].txtcolLbsToApply) != e.target.value){
        rowsData[i].txtcolLbsToApply = vale;
            setValue(thisObj,'gridContractApplications',rowsData)
            _kaledo.thisObj.setState(current => ({
                ...current,
                gridContractApplications: {
                  ...current.gridContractApplications,
                  toggleRender: !state.gridContractApplications.toggleRender
                }
              }))
              
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");     
      }
    }
    }
    ComputeOrderedValues()
    hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
    hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
    hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");     
  }
  thisObj.ontxtcolLbsToApplyBlur = ontxtcolLbsToApplyBlur;

  //Code for Enable Disable controls
  const EnableDisableControls = () => {
    let Mode = getData(thisObj, "Mode");
    enable(thisObj, "txtOrder");
    enable(thisObj, "txtLbsToApply");
    let value3 = getValue(thisObj, "txtPrice");
    if (value3 == "" || value3 == undefined) {
      disable(thisObj, "txtLbsToApply");
      disable(thisObj, "txtOrder");
    } else {
      if (Number(value3 == 0)) {
        disable(thisObj, "txtLbsToApply");
        disable(thisObj, "txtOrder");
      }
    }
    show(thisObj, "txtLbsToApply");
    show(thisObj, "txtOrder");
    show(thisObj, "txtPrice");
    show(thisObj, "txtPounds");
    if (Mode === "TENTATIVE_APPLICATION") {
      disable(thisObj, "lblSpotApplication");
      hide(thisObj, "lblSpotApplication", false);
      hide(thisObj, "txtOrder", false);
      hide(thisObj, "txtPrice", false);
      hide(thisObj, "txtLbsToApply", false);
    }
    if (
      [mbtent_cont_appl_ind === "O" || mbtent_cont_appl_ind === "I"] &&
      Mode === "PURCHASE"
    ) {
      disable(thisObj, "txtOverideFarmNum");
      disable(thisObj, "btnClearValues");
      hide(thisObj, "btnClearValues", false);
      hide(thisObj, "lblSeedGrowerApplications", false);
      disable(thisObj, "gridSeedGrowerApplications");
      hide(thisObj, "gridSeedGrowerApplications", false);
      hide(thisObj, "txtSeedLbs", false);
    } else {
      enable(thisObj, "txtOverideFarmNum");
      enable(thisObj, "btnClearValues");
      show(thisObj, "btnClearValues");
      show(thisObj, "lblSeedGrowerApplications");
      enable(thisObj, "gridSeedGrowerApplications");
      show(thisObj, "gridSeedGrowerApplications");
      show(thisObj, "txtSeedLbs");
      let d1 = getData(thisObj, "txtSeedLbs");
      if (Mode === "TENTATIVE_APPLICATION") {
        if (d1 == 0 || d1 == "") {
          hide(thisObj, "txtSeedLbs", false);
          disable(thisObj, "gridSeedGrowerApplications");
          hide(thisObj, "gridSeedGrowerApplications", false);
          hide(thisObj, "lblSeedGrowerApplications", false);
        }
      }
    }
    if (Number(cropYear) >= 2009) {
      hide(thisObj, "txtOverideFarmNum", false);
    } else {
      show(thisObj, "txtOverideFarmNum");
    }
  };

  //Code for bFillContractApplicationsTentative
  const bFillContractApplicationsTentative = async () => {
    let penutId = "";
    let lblOverrideFarm = getData(thisObj, "lblOverrideFarm");
    let lblBuyPtIdValue = getData(thisObj, "lblBuyPtIdValue");
    let lblWRNumberValue = getData(thisObj, "lblWRNumberValue");
    let lblUnloadBuyPtIdValue = getData(thisObj, "lblUnloadBuyPtIdValue");
    let lblVarietyValue = getData(thisObj, "lblVarietyValue");
    let Mode = getData(thisObj, "Mode");
    let lblDMAVendorText = getData(thisObj, "lblDMAVendorText");
    let lblDMAVendorValue = getData(thisObj, "lblDMAVendorValue");
    let lblVendorValue = getData(thisObj, "lblVendorValue");
    let responseTentive = await WarehouseReceiptService.RetrieveWareHouseReciptHeaderDetails(
      lblBuyPtIdValue,
      lblWRNumberValue,
      null,
      null,
      null,
      null,
      null,
      null,
      "Y"
    );
    if (responseTentive.length > 0) {
      setValue(thisObj, "txtPeanutType", responseTentive[0].pnut_type_name);
      setData(thisObj, "tentivePeanutTypeId", responseTentive[0].pnut_type_id);
      let lblSeed = responseTentive[0].unld_seed_load_found;
      setData(thisObj,'lblSeed',lblSeed)
      //lblSeed.Caption = GetInside(lstrxml, "<unld_seed_load_found>", "</unld_seed_load_found>")
      setValue(thisObj, "txtPounds", responseTentive[0].net_wt);
      setData(thisObj, "txtPounds", responseTentive[0].net_wt);
      let tentivesSeg = responseTentive[0].seg_type;
      let lblPounds = responseTentive[0].net_wt;
      let dtIssueDate = responseTentive[0].issued_date;
      let mdtWRDate = responseTentive[0].issued_date;
      setData(thisObj, "mdtWRDate", mdtWRDate);
      let rcpt_status = responseTentive[0].rcpt_status;
      setData(thisObj, "rcpt_status", rcpt_status);
      setValue(thisObj, "txtSeedLbs", responseTentive[0].seed_lbs);
      setData(thisObj, "txtSeedLbs", responseTentive[0].seed_lbs);
    } else {
      bFilling = false;
      return false;
    }

    let func_id = "PN1090";
    let func_sub_id = "";
    let farm_id = null;
    let pnut_type_id = responseTentive[0].pnut_type_id;
    let pnut_variety_id = lblVarietyValue;
    let whse_rcpt_num = lblWRNumberValue;
    let seed_grower = null;
    let seg_type = sSeg;
    let txtOverideFarmNum1 = getValue(thisObj, "txtOverideFarmNum");
    let txtOverideFarmNum =
      txtOverideFarmNum1 === undefined ? "" : txtOverideFarmNum1;
    let vendor = lblVendorValue;

    let data = null;
    let contApArr = [];
    let cAobj = {};
    let sGaArr = [];
    let sGaobj = {};

    if (lblDMAVendorText != "") {
      if (txtOverideFarmNum === "") {
        let rs = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblUnloadBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          vendor,
          txtOverideFarmNum,
          seed_grower,
          seg_type
        );
        if (rs.length > 0) {
          data = rs;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      } else {
        let rs1 = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblUnloadBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          vendor,
          txtOverideFarmNum,
          seed_grower,
          seg_type
        );
        if (rs1.length > 0) {
          data = rs1;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      }
    } else {
      if (txtOverideFarmNum === "") {
        let rs2 = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblUnloadBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          vendor,
          farm_id,
          seed_grower,
          seg_type
        );
        if (rs2.length > 0) {
          data = rs2;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      } else {
        let rs3 = await WarehouseReceiptService.RetrieveWareHouseContractDetails(
          lblUnloadBuyPtIdValue,
          func_id,
          user_id,
          whse_rcpt_num,
          Mode,
          func_sub_id,
          pnut_type_id,
          pnut_variety_id,
          vendor,
          txtOverideFarmNum,
          seed_grower,
          seg_type
        );
        if (rs3.length > 0) {
          data = rs2;
        } else {
          bFilling = false;
          EnableDisableControls();
          return false;
        }
      }
    }

    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        sContractDate = data[i].cont_date;
        sBuyPoint = data[i].buy_pt_id;
        sContractNum = data[i].contract_num;
        sContractType = data[i].cont_type;
        sGroupingNum = data[i].grouping_num;

        if (sContractType === "S") {
          if (
            Number(data[i].addendum_seed_grower) != 0 ||
            data[i].seed_tbd === "Y"
          ) {
            let value55 = getData(thisObj,'lblSeed') 
            if (value55 == "Y") {
              //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 0, vsfDataSGA.Row, 8) = &HC0C0C0
              if (mbtent_cont_appl_ind == "O" || mbtent_cont_appl_ind == "I") {
                //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 9, vsfDataSGA.Row, 9) = &HC0C0C0
              } else {
                //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 9, vsfDataSGA.Row, 9) = &HFFFFFF
              }
              //vsfDataSGA.Cell(flexcpBackColor, vsfDataSGA.Row, 10, vsfDataSGA.Row, 30) = &HC0C0C0

              if (
                Number(data[i].seed_grower_cont_lbs) >
                Number(data[i].seed_grower_appl_lbs)
              ) {
                mbUnappliedSeedContAvail = true;
              }
              sGaobj.txtcol1SGA = ""; //1
              sGaobj.txtcolBuyPtSGA = sBuyPoint; //2
              sGaobj.txtcolContractNumberSGA = sContractNum; //3
              sGaobj.txtcolConractDateSGA = sContractDate; //4
              let sGasymbolmark = "";
              switch (data[i].symbol_ind) {
                case "TRADEMARK":
                  sGasymbolmark = " " + " \u2122";
                  break;
                case "REGISTERED TRADEMARK":
                  sGasymbolmark = " " + " \xAE";
                  break;
                case "COPYRIGHT":
                  sGasymbolmark = " " + " \xA9";
                  break;
                default:
                // code block
              }
              sGaobj.txtcolPeanutVarietySGA =
                data[i].pnut_variety_name + sGasymbolmark; //5
              sGaobj.txtcolSeedSGA = data[i].seed_grower; //6
              sGaobj.txtcolOrderSGA = ""; //8
              sGaobj.txtcolPoundsToApplySGA = ""; //9
              sGaobj.txtcolContTypeSGA = sContractType; //12
              sGaobj.txtcolContractLbsSGA = data[i].seed_grower_cont_lbs; //15
              sGaobj.txtcolAppliedSGA = data[i].seed_grower_appl_lbs; //16
              sGaobj.txtcolOpenBalance1SGA =
                Number(data[i].seed_grower_cont_lbs) -
                Number(data[i].seed_grower_appl_lbs); //17
              sGaobj.txtcolTentAppliedSGA = ""; //18
              sGaobj.txtcoltotalApplicationSGA =
                Number(data[i].seed_grower_appl_lbs) + 0; //19
              sGaobj.txtcolOpenBalance2SGA =
                Number(data[i].seed_grower_cont_lbs) -
                Number([Number(data[i].seed_grower_appl_lbs) + 0]); //20
              sGaobj.txtcolPriceSGA = data[i].seed_grower_prem_per_ton; //21
              sGaobj.txtcolSeedTBDSGA = data[i].seed_tbd;
              sGaArr.push(sGaobj);
              cAobj = {};
            }
          }
          // sContract =data[i].addendum_seed_grower
        } else {
          if (Number(data[i].contract_pricing) != 0) {
            // vsfData.Cell(flexcpBackColor, vsfData.Row, 0, vsfData.Row, 7) = &HC0C0C0
            // vsfData.Cell(flexcpBackColor, vsfData.Row, 8, vsfData.Row, 9) = &HFFFFFF
            // vsfData.Cell(flexcpBackColor, vsfData.Row, 10, vsfData.Row, 30) = &HC0C0C0

            if (Number(sGroupingNum) != "" && sHoldGrpNum != sGroupingNum)
              cAobj.txtcolGrouping = data[i].grouping_num; //1
            sHoldGrpNum = sGroupingNum;
          }
          if (sGroupingNum != "") {
            //vsfData.Cell(flexcpBackColor, vsfData.Row, 0, vsfData.Row, 30) = &HC0C0C0
          }
          cAobj.txtcolBuyPt = sBuyPoint; //2
          cAobj.txtcolContractNumber = sContractNum; //3
          cAobj.txtcolContractDate = sContractDate.split(' ')[0] //4
          let symbolmark = "";
          switch (data[i].symbol_ind) {
            case "TRADEMARK":
              symbolmark = " " + " \u2122";
              break;
            case "REGISTERED TRADEMARK":
              symbolmark = " " + " \xAE";
              break;
            case "COPYRIGHT":
              symbolmark = " " + " \xA9";
              break;
            default:
            // code block
          }
          cAobj.txtcolPeanutVariety = data[i].pnut_variety_name + symbolmark; //5

          if (data[i].seed_grower != "") {
            cAobj.txtcolSeed = data[i].seed_grower; //6
          } else {
            cAobj.txtcolSeed = data[i].seed_grower; //6
          }

          if (data[i].firm_basis == "F") {
            cAobj.txtcolFOrB = "Firm"; //7
          } else {
            cAobj.txtcolFOrB = "Basis";
            //vsfData.TextMatrix(vsfData.Row, 7) = "Basis"
          }
          cAobj.txtcolOrder = ""; //8
          cAobj.txtcolLbsToApply = ""; //9
          cAobj.txtcolGrpSharePct = data[i].grouping_share; //10
          cAobj.txtcolGrpShareLbs = ""; //11
          cAobj.txtcolContType = sContractType; //12
          cAobj.txtcolDelvStartDt = data[
            i
          ].contract_pricings[0].delv_beg_date.slice(0, 9); //13
          cAobj.txtcolDelvEndDt = data[
            i
          ].contract_pricings[0].delv_end_date.slice(0, 10); //14
          cAobj.txtcolContractLbs = data[i].contract_pricings[0].cont_lbs; //15
          cAobj.txtcolApplied = data[i].contract_pricings[0].appl_lbs; //16
          cAobj.txtcolSeedOpen =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number(data[i].contract_pricings[0].appl_lbs); //17
          cAobj.txtcolTentApplied = data[i].contract_pricings[0].tentative_lbs; //18
          cAobj.txtcolTotalApplication =
            Number(data[i].contract_pricings[0].appl_lbs) +
            Number(data[i].contract_pricings[0].tentative_lbs); //19
          cAobj.txtcolOpenBalance =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number([
              Number(data[i].contract_pricings[0].appl_lbs) +
                Number(data[i].contract_pricings[0].tentative_lbs),
            ]); //20
          cAobj.txtcolPrice = data[i].contract_pricings[0].price_per_ton; //21
          cAobj.col22 = data[i].contract_pricings[0].appl_lbs; //22
          cAobj.col23 = data[i].contract_pricings[0].tentative_lbs; //23
          cAobj.col24 =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number(data[i].contract_pricings[0].appl_lbs); //24
          cAobj.col25 =
            Number(data[i].contract_pricings[0].cont_lbs) -
            Number(data[i].contract_pricings[0].appl_lbs) -
            Number(data[i].contract_pricings[0].tentative_lbs); //25
          cAobj.col26 = data[i].grouping_date_time; //26
          cAobj.txtcolSeedCont = data[i].seed_adden_cont_num; //28
          cAobj.txtcolSeedOpen =
            Number(data[i].seed_grower_cont_lbs) -
            Number(data[i].seed_grower_appl_lbs); //29
          cAobj.txtcolSeedTBD = data[i].seed_tbd;
          contApArr.push(cAobj);
          cAobj = {};
        }
      }

      let result = await WarehouseReceiptService.RetrieveWarehouseContractApplicationDetails(
        lblBuyPtIdValue,
        lblWRNumberValue
      );
      if (result.length > 0) {
        for (let j = 0; j < result.length; j++) {
          if (Number(result[j].pricing_line) === 1) {
            if (
              contApArr[j].txtcolContractNumber === result[j].contract_num &&
              cAcol7 == "Firm"
            ) {
              contApArr[j].txtcolApplied =
                Number(contApArr[j].txtcolApplied) -
                Number(result[j].appl_lbs_adj); //16
              contApArr[j].txtcolSeedOpen =
                contApArr[j].txtcolContractLbs - contApArr[j].txtcolApplied;
              contApArr[j].txtcolTotalApplication =
                contApArr[j].txtcolApplied + contApArr[j].txtcolTentApplied;
              contApArr[j].txtcolOpenBalance =
                contApArr[j].txtcolContractLbs -
                contApArr[j].txtcolTotalApplication;
              contApArr[j].txtcolTentApplied =
                Number(contApArr[j].txtcolTentApplied) -
                Number(result[j].tent_lbs_adj); //18
              contApArr[j].txtcolTotalApplication =
                contApArr[j].txtcolApplied + contApArr[j].txtcolTentApplied;
              contApArr[j].txtcolOpenBalance =
                contApArr[j].txtcolContractLbs -
                contApArr[j].txtcolTotalApplication;
              contApArr[j].txtcolTotalApplication =
                Number(contApArr[j].txtcolApplied) +
                Number(contApArr[j].txtcolTentApplied); //19
              contApArr[j].col22 =
                Number(contApArr[j].col22) - Number(result[j].appl_lbs_adj); //22
              contApArr[j].col23 =
                Number(contApArr[j].col24) - Number(result[j].tent_lbs_adj); //23
              contApArr[j].col24 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied); //24
              contApArr[j].col25 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied) -
                Number(contApArr[j].tentative_lbs); //25
            }
          } else {
            if (
              contApArr[j].txtcolContractNumber === result[j].contract_num &&
              cAcol7 == "Basis"
            ) {
              contApArr[j].txtcolApplied =
                Number(contApArr[j].txtcolApplied) -
                Number(result[j].appl_lbs_adj); //16

              contApArr[j].txtcolTentApplied =
                Number(contApArr[j].txtcolTentApplied) -
                Number(result[j].tent_lbs_adj); //18
              contApArr[j].txtcolTotalApplication =
                Number(contApArr[j].txtcolApplied) +
                Number(contApArr[j].txtcolTentApplied); //19
              contApArr[j].col22 =
                Number(contApArr[j].col22) - Number(result[j].appl_lbs_adj); //22
              contApArr[j].col23 =
                Number(contApArr[j].col23) - Number(result[j].tent_lbs_adj); //23
              contApArr[j].col24 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied); //24
              contApArr[j].col25 =
                Number(contApArr[j].txtcolContractLbs) -
                Number(contApArr[j].txtcolApplied) -
                Number(contApArr[j].tentative_lbs); //25
            }
          }
        }
      }
      setValue(thisObj, "gridSeedGrowerApplications", sGaArr);
      setValue(thisObj, "gridContractApplications", contApArr);
      setData(thisObj, "gridContractApplications", contApArr);
      // hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
      // hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
      // hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
    }
    setValue(thisObj, "txtLbsToApply", "");
    let bFillContractApplicationsTentative = true;
    let nOrder = 1;
    let nPounds =
      Number(getValue(thisObj, "txtPounds") || getData(thisObj, "txtPounds"));
    let bPoundsLeft = true;
    EnableDisableControls();
    let gridData = getValue(thisObj, "gridContractApplications");
    for (let i = 0; i < gridData.length; i++) {
      let data25 =
        gridData[i].txtcolGrouping == undefined
          ? ""
          : gridData[i].txtcolGrouping;
      if (!(isNaN(data25) == true)) {
        if (!(isNaN(gridData[i].txtcolGrpSharePct) == true)) {
          if (nPounds <= gridData[i].txtcolOpenBalance) {
            if (bPoundsLeft == true) {
              gridData[i].txtcolOrder = nOrder;
              setValue(thisObj,'gridContractApplications',gridData)
              _kaledo.thisObj.setState(current => ({
                ...current,
                gridContractApplications: {
                  ...current.gridContractApplications,
                  toggleRender: !state.gridContractApplications.toggleRender
                }
              }))
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
              if (!isNaN(gridData[i].txtcolOrder) == false) {
                gridData[i].txtcolOrder = "";
                return;
              }
              if (isNaN(gridData[i].txtcolLbsToApply) == true) {
                return;
              }
              await ComputeOrderedValues();
              let bPoundsLeft = false;
            }
          } else {
            if (gridData[i].txtcolOpenBalance > 0) {
              if (bPoundsLeft == true) {
                nPounds = nPounds - gridData[i].txtcolOpenBalance;
                gridData[i].txtcolOrder = nOrder;
                setValue(thisObj,'gridContractApplications',gridData)
                _kaledo.thisObj.setState(current => ({
                  ...current,
                  gridContractApplications: {
                    ...current.gridContractApplications,
                    toggleRender: !state.gridContractApplications.toggleRender
                  }
                }))
                hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
                hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
                hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
                if (!isNaN(gridData[i].txtcolOrder) == false) {
                  gridData[i].txtcolOrder = "";
                  return;
                }
                if (isNaN(gridData[i].txtcolLbsToApply) == true) {
                  return;
                }
                await ComputeOrderedValues();
                nOrder = nOrder + 1;
              } else {
                // vsfData.Cell(flexcpBackColor, nListIndex, 8, nListIndex, 9) = &HC0C0C0
              }
            }
          }
        } else {
          let nGrpPounds = 0;
          if (isNaN(gridData[i].txtcolGrpSharePct) == true) {
            nGrpPounds = nGrpPounds + gridData[i].txtcolOpenBalance;
          }
          if (nGrpPounds > 0) {
            if (nPounds <= nGrpPounds) {
              if (bPoundsLeft == true) {
                gridData[i].txtcolOrder = nOrder;
                bPoundsLeft = False;
              }
            } else {
              if (bPoundsLeft == true) {
                gridData[i].txtcolOrder = nOrder;
                if (isNaN(gridData[i].txtcolOrder) == true) {
                  nPounds = nPounds - nGrpPounds;
                  nOrder = nOrder + 1;
                }
              }
            }
          } else {
            // vsfData.Cell(flexcpBackColor, nGrpIndex, 8, nGrpIndex, 9) = &HC0C0C0
          }
        }
      } else {
        nGrpPounds = 0;
        nGrpIndex = nListIndex;
      }
    }
    for (let i = 0; i < gridData.length; i++) {
      gridData[i].col27 = gridData[i].txtcolLbsToApply;
      setGridValue(
        _kaledo.thisObj,
        "gridContractApplications",
        "txtcolLbsToApply",
        i,
        gridData[i].col27
      );
    }
    bFilling = false;
   
    // EnableDisableControls();
  };

  //Code for rowNo
  const ongridContractApplicationsRowSelect = (rowSelected) => {

    setRowNo(getSelectedRowNumber(thisObj, 'gridContractApplications'))

}
  thisObj.ongridContractApplicationsRowSelect = ongridContractApplicationsRowSelect;

  //Code for clear Values
  const onbtnClearValuesClick = () => {
    let gridData = getValue(thisObj, "gridContractApplications");
    for (let i = 0; i < gridData.length; i++) {
      setGridValue(thisObj, "gridContractApplications", "txtcolOrder", i, "");
      setGridValue(
        thisObj,
        "gridContractApplications",
        "txtcolLbsToApply",
        i,
        ""
      );
    }
    let gridData1 = getValue(thisObj, "gridSeedGrowerApplications");
    for (let i = 0; i < gridData1.length; i++) {
      setGridValue(
        thisObj,
        "gridContractApplications",
        "txtcolPoundsToApplySGA",
        i,
        ""
      );
    }
  };
  thisObj.onbtnClearValuesClick = onbtnClearValuesClick;

  //Code for Cancel Click
  const onbtnCancelClick = () => {
    setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', { flag : true })
    document
      .getElementById("WarehouseReceipts_WhouseApplicationsPopUp")
      .childNodes[0].click();
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  //Code for OK button
  const onbtnOkClick = async () => {
    try {
      if (!(await bFormValid())) {
        return;
      }
      if (!(await bFillContractList())) {
        return;
      }
      
      let lstNewContractLine = getData(thisObj, "lstNewContractLine")
      setData(thisObj, "frmWhouseApplicationOKFlag", {
        flag: true,
        mode: getData(thisObj, "frmWhouseApplications")?.Mode,
        lstNewContractLine: getData(thisObj, "lstNewContractLine"),
      });
      setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', null)
      // let lstNewContractLine = getData(thisObj, "lstNewContractLine")
      // setData(thisObj, "lstNewContractLineFromWhouseApplications", lstNewContractLine)
      // setData(thisObj, "lblApplicationsCanceled", false)
      // setData(thisObj, "lblOverrideFarm",getValue(thisObj,'txtOverideFarmNum'))
      // goTo(thisObj,"WarehouseReceipts#PremiumDeductionsWRPurch#DEFAULT#true#Click")
      document.getElementById("WarehouseReceipts_WhouseApplicationsPopUp").childNodes[0].click();
    } catch (err) {
      showMessage(
        thisObj,
        err instanceof EvalError
          ? err.message
          : "Something went wrong. Please try again"
      );
    }
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  //Code for btncol1Click
  const onbtncol1Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow");
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow");
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement;
      rowSelected.classList.add("activeRow");
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue = getValue(_kaledo.thisObj, "gridContractApplications");
      if (
        NewGridValue[rowID].txtcolContractNumber != null &&
        NewGridValue[rowID].txtcolContractNumber != undefined &&
        NewGridValue[rowID].txtcolContractNumber != ""
      ) {
        let contractnum = NewGridValue[rowID].txtcolContractNumber;
        if (Number(contractnum) > 0) {
          commonctx.setContractProfile_ContractNumber(contractnum);
          commonctx.setContractProfile_FormState("edit");
          goTo(
            thisObj,
            "ContractManagement#ContractProfile#DEFAULT#true#Click"
          );
        } else {
          showMessage(thisObj, "Contract does not exist");
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!");
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtncol1Click = onbtncol1Click;

  //Code for btncol2Click
  const onbtnCol2Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow");
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow");
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement;
      rowSelected.classList.add("activeRow");
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue = getValue(
        _kaledo.thisObj,
        "gridSeedGrowerApplications"
      );
      if (
        NewGridValue[rowID].txtcolContNumSG != null &&
        NewGridValue[rowID].txtcolContNumSG != undefined &&
        NewGridValue[rowID].txtcolContNumSG != ""
      ) {
        let contractnum = NewGridValue[rowID].txtcolContNumSG;
        if (Number(contractnum) > 0) {
          commonctx.setContractProfile_ContractNumber(contractnum);
          commonctx.setContractProfile_FormState("edit");
          goTo(
            thisObj,
            "ContractManagement#ContractProfile#DEFAULT#true#Click"
          );
        } else {
          showMessage(thisObj, "Contract does not exist");
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!");
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtnCol2Click = onbtnCol2Click;

  //Function for InitContractApplicationsGrid
  async function InitContractApplicationsGrid() {
    showColumn(thisObj, "gridContractApplications", "txtcolGrouping");
    showColumn(thisObj, "gridContractApplications", "txtcolBuyPt");
    showColumn(thisObj, "gridContractApplications", "txtcolContractNumber");
    showColumn(thisObj, "gridContractApplications", "txtcolContractDate");
    showColumn(thisObj, "gridContractApplications", "txtcolPeanutVariety");
    showColumn(thisObj, "gridContractApplications", "txtcolSeed");
    showColumn(thisObj, "gridContractApplications", "txtcolFOrB");
    showColumn(thisObj, "gridContractApplications", "txtcolOrder");
    showColumn(thisObj, "gridContractApplications", "txtcolLbsToApply");
    showColumn(thisObj, "gridContractApplications", "txtcolGrpSharePct");
    showColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
    showColumn(thisObj, "gridContractApplications", "txtcolContType");
    showColumn(thisObj, "gridContractApplications", "txtcolDelvStartDt");
    showColumn(thisObj, "gridContractApplications", "txtcolDelvEndDt");
    showColumn(thisObj, "gridContractApplications", "txtcolContractLbs");
    showColumn(thisObj, "gridContractApplications", "txtcolApplied");
    showColumn(thisObj, "gridContractApplications", "txtcolTentApplied");
    showColumn(thisObj, "gridContractApplications", "txtcolTotalApplication");
    showColumn(thisObj, "gridContractApplications", "txtcolOpenBalance");
    showColumn(thisObj, "gridContractApplications", "txtcolPrice");
    showColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
    showColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
    showColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
  }

  //Function for InitSeedGrowerApplicationsGrid
  async function InitSeedGrowerApplicationsGrid() {
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcol1SGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolBuyPtSGA");
    showColumn(
      thisObj,
      "gridSeedGrowerApplications",
      "txtcolContractNumberSGA"
    );
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolContractDateSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolPeanutVarietySGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolSeedSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolOrderSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolPoundsToApplySGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolContTypeSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolContractLbsSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolAppliedSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolOpenBalance1SGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolTentAppliedSGA");
    showColumn(
      thisObj,
      "gridSeedGrowerApplications",
      "txtcoltotalApplicationSGA"
    );
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolOpenBalance2SGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolPriceSGA");
    showColumn(thisObj, "gridSeedGrowerApplications", "txtcolSeedTBDSGA");
  }

  //Function for form Validations
  const bFormValid = async () => {
    let bFormValid = false;
    let value1 = getData(thisObj, "rcpt_status");
    if (value1 != "F") {
      let gridData = getValue(thisObj, "gridContractApplications");
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolOrder != "") {
          if (isNaN(gridData[i].txtcolOrder) == true) {
            alert("Order must be numeric.");
            return;
          }
        }
      }
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolLbsToApply != "") {
          let data12 = isNaN(gridData[i].txtcolLbsToApply);
          if (data12 == false) {
            if (
              parseFloat(gridData[i].txtcolLbsToApply) < 0 ||
              parseFloat(gridData[i].txtcolLbsToApply) > 999999999
            ) {
              alert("Pounds to Apply must be of format #########.");
              return;
            }
            let data13 = gridData[i].txtcolSeedTBD.trim();
            if (data13 == "Y") {
              alert(
                "Seed varieties have not been defined.  Contract number: " +
                  gridData[i].txtcolBuyPt
              );
              return;
            }
          } else {
            alert("Pounds to Apply must be of format #########.");
            return;
          }
          if (gridData[i].txtcolGrouping == "") {
            let mdtWRDate1 = getData(thisObj, "mdtWRDate").slice(0, 10);
            let mdtWRDate = new Date(mdtWRDate1)
            let delv_beg_date1 = moment(
              gridData[i].txtcolDelvStartDt.trim() + " 12:00:00 AM"
            ).format("MM/DD/YYYY");
            let delv_beg_date = new Date(delv_beg_date1)
            let delv_end_date1 = moment(
              gridData[i].txtcolDelvEndDt.trim() + " 11:59:59 PM"
            ).format("MM/DD/YYYY");
            let delv_end_date = new Date(delv_end_date1)
            if (mdtWRDate < delv_beg_date || mdtWRDate > delv_end_date) {
              alert(
                "Contract " +
                  gridData[i].txtcolContractNumber +
                  " delivery date does not match Warehouse Receipt date."
              );
              return;
            }
          }
        }
      }
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolGrpShareLbs != "") {
          let mdtWRDate1 = getData(thisObj, "mdtWRDate").slice(0, 10);
          let mdtWRDate = new Date(mdtWRDate1)
          let delv_beg_date1 = moment(
            gridData[i].txtcolDelvStartDt.trim() + " 12:00:00 AM"
          ).format("MM/DD/YYYY");
          let delv_beg_date = new Date(delv_beg_date1)
          let delv_end_date1 = moment(
            gridData[i].txtcolDelvEndDt.trim() + " 11:59:59 PM"
          ).format("MM/DD/YYYY");
          let delv_end_date = new Date(delv_end_date1)
          if (mdtWRDate < delv_beg_date || mdtWRDate > delv_end_date) {
            alert(
              "Contract " +
                gridData[i].txtcolContractNumber +
                " delivery date does not match Warehouse Receipt date."
            );
            return;
          }
        }
      }
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolLbsToApply != "") {
          if (gridData[i].txtcolGrpSharePct != "") {
            if (gridData[i].txtcolGrpShareLbs != "") {
              if (isNaN(gridData[i].txtcolGrpShareLbs) == true) {
                if (
                  parseFloat(gridData[i].txtcolGrpShareLbs) < 0 ||
                  parseFloat(gridData[i].txtcolGrpShareLbs) > 999999999
                ) {
                  alert(
                    "Grouping Pounds to Apply must be of format #########."
                  );
                  return;
                } else {
                  alert(
                    "Grouping Pounds to Apply must be of format #########."
                  );
                  return;
                }
              }
            }
          }
        }
      }
      let value3 = getValue(thisObj, "txtOrder");
      if (!(value3 == "" || value3 == null || value3 == undefined)) {
        if (isNaN(value3) == true) {
          if (parseFloat(value3) != parseInt(value3)) {
            alert("Order must be an integer.");
            document.getElementById("txtOrder").focus();
            return;
          } else {
            alert("Order must be an integer.");
            return;
          }
        }
      }
      let value10 = getValue(thisObj, "txtLbsToApply");
      if (value10 != "") {
        if (isNaN(txtLbsToApply) == false) {
          if (
            parseFloat(txtLbsToApply) < 0 ||
            parseFloat(txtLbsToApply) > 999999999
          ) {
            alert("Pounds to Apply must be of format #########.");
            return;
          } else {
            alert("Pounds to Apply must be of format #########.");
            return;
          }
        }
      }
    }
    let nAddenSeedLbsPriceLineApplied = 0;
    let nAddenSeedLbsApplied = 0;
    let nAddenSeedLbs = 0;
    let mlAddenSeedLbsLeft = getValue(thisObj, "txtSeedLbs");
    let nApplPoundsToApply = 0;

    if (value1 != "F") {
      let gridData = getValue(thisObj, "gridContractApplications");
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].txtcolGrouping == "") {
          if (gridData[i].txtcolSeedOpen < 0) {
            alert(
              "Negative Open Balance Exists. Contract - " +
                gridData[i].txtcolContractNumber
            );
            return;
          }
          if (
            Number(gridData[i].txtcolOpenBalance) < 0 &&
            Number(gridData[i].txtcolLbsToApply) > 0
          ) {
            alert(
              "Negative Tentative Open Balance Exists. Contract - " +
                gridData[i].txtcolContractNumber
            );
            return;
          }
        }
        let value11 =
          gridData[i].txtcolLbsToApply === ""
            ? 0
            : Number(gridData[i].txtcolLbsToApply);
        nApplPoundsToApply = Number(nApplPoundsToApply) + value11;
        if (gridData[i].txtcolLbsToApply > 0) {
          if (gridData[i].txtcolSeed == "Y") {
            nAddenSeedLbsApplied = gridData[i].txtcolLbsToApply;
            nAddenSeedLbs = gridData[i].txtcolSeedOpen;
            if (nAddenSeedLbsApplied <= nAddenSeedLbs) {
               nAddenSeedLbsPriceLineApplied =
                nAddenSeedLbsPriceLineApplied + nAddenSeedLbsApplied;
            } else {
              nAddenSeedLbsPriceLineApplied =
                nAddenSeedLbsPriceLineApplied + nAddenSeedLbs;
            }
          }
        }
      }
      let value12 =
        getValue(thisObj, "txtLbsToApply") === undefined
          ? 0
          : Number(getValue(thisObj, "txtLbsToApply"));
      nApplPoundsToApply = Number(nApplPoundsToApply) + value12;
      let value4 = getValue(thisObj, "txtPounds");
      if (nApplPoundsToApply > value4) {
        alert("Too many purchase pounds applied.");
        return;
      }
      mlAddenSeedLbsLeft = mlAddenSeedLbsLeft - nAddenSeedLbsPriceLineApplied;
      let nAddenSeedLbsNonPriceLineApplied = 0;

      let gridData1 = getValue(thisObj, "gridSeedGrowerApplications");
      for (let i = 0; i < gridData1.length; i++) {
        if (isNaN(gridData1[i].txtcolContractNumberSGA) == true) {
          if (gridData1[i].txtcolPoundsToApplySGA > 0) {
            let value4 = gridData1[i].txtcolSeedTBDSGA;
            if (value4 == "Y") {
              alert(
                "Seed varieties have not been defined.  Contract number:" +
                  gridData1[i].txtcolContractNumberSGA
              );
            }
            let value5 = gridData1[i].txtcolPoundsToApplySGA;
            let value6 = gridData1[i].txtcolOpenBalance2SGA;
            if (value5 > value6) {
              alert(
                "Too many seed pounds applied to seed grower addendum " +
                  gridData1[i].txtcolContractNumberSGA
              );
              return;
            }
            nAddenSeedLbsNonPriceLineApplied =
              nAddenSeedLbsNonPriceLineApplied +
              gridData1[i].txtcolPoundsToApplySGA;
          }
        }
      }
      mlAddenSeedLbsLeft =
        mlAddenSeedLbsLeft - nAddenSeedLbsNonPriceLineApplied;
      let value5 = getValue(thisObj, "txtPounds");
      if (
        nAddenSeedLbsPriceLineApplied + nAddenSeedLbsNonPriceLineApplied >
        value5
      ) {
        alert("Too many seed grower addendum pounds applied.");
        return;
      }
      let Mode = getData(thisObj, "Mode");
      if (nApplPoundsToApply != value5) {
        if (Mode == "TENTATIVE_APPLICATION") {
          let data11 = Number(value5) - Number(nApplPoundsToApply);
          alert(
            "Not all pounds distributed.\nPounds to apply = " +
              data11 +
              "\nAll Pounds on Warehouse Receipt must be tentatively applied to one or more contracts."
          );
        } else {
          const confirmBox = window.confirm(
            "Not all pounds distributed. Would you like to distribute the rest to spot?"
          );
          setData(thisObj, "confirmBox", confirmBox);
        }
        if (getData(thisObj, "confirmBox") == true) {
          if (isEnabled(thisObj, "txtLbsToApply") == true) {
            lbsapplyTotalValue =
              Number(value5) -
              Number(nApplPoundsToApply) +
              getValue(thisObj, "txtLbsToApply");
            setValue(thisObj, "txtLbsToApply", lbsapplyTotalValue);
          } else {
            alert("Zero Spot Price. Unable to apply pounds");
            return;
          }
        } else {
          return;
        }
      }

      if (
        !(
          [mbtent_cont_appl_ind === "O" || mbtent_cont_appl_ind === "I"] &&
          Mode === "PURCHASE"
        )
      ) {
        if (mlAddenSeedLbsLeft > 0 && mbUnappliedSeedContAvail) {
          confirmBox = window.confirm(
            "Would you like to apply anymore seed pounds to seed grower addendum?"
          );
          if (confirmBox == true) {
            return;
          }
        }
        if (mlAddenSeedLbsLeft < 0 && mbUnappliedSeedContAvail) {
          confirmBox = window.confirm(
            "Too many pounds applied to seed grower addendum."
          );
          return;
        }
      }
    }
    bFormValid = true;
    return bFormValid;
  };

  //Function for bFillContractList
  async function bFillContractList() {
    let lstNewContractLine = []; //     lstNewContractLine.Clear
    let bFillContractListflag = false;
    let nContractCount = 0;
    let vsfData = getValue(thisObj, "gridContractApplications");
    let Mode = getData(thisObj,'Mode')
    for (let i = 0; i < vsfData.length; i++) {
      let data45 = vsfData[i].txtcolLbsToApply == ""? undefined:vsfData[i].txtcolLbsToApply
      if (isNaN(data45) == false) {
        let data2 = isNaN(vsfData[i].txtcolGrouping);
        data2 = getData(thisObj, 'frmWhouseApplications')?.methodName == 'bFillContractApplicationsTentative'
                                                          ? data2  :  !data2
        if (data2) {
          let str = vsfData[i].txtcolContractNumber + "|";
          str += vsfData[i].txtcolFOrB + "|";
          str += vsfData[i].txtcolLbsToApply + "|A|";
          str += vsfData[i].txtcolPrice + "|";
          str += vsfData[i].txtcolSeed + "|";
          str += vsfData[i].txtcolContType + "|";
          str += (vsfData[i].col27 === undefined ? 0 : vsfData[i].col27) + "|";
          str += vsfData[i].col28 === undefined ? 0 : vsfData[i].col28;
          lstNewContractLine.push(str);
        } else {
          if (
            [mbtent_cont_appl_ind === "O" || mbtent_cont_appl_ind === "I"] &&
            Mode === "PURCHASE"
          ) {
            let data46 = vsfData[i].txtcolGrpShareLbs == ""? undefined:vsfData[i].txtcolGrpShareLbs
            if (isNaN(data46) == false) {
              let str = vsfData[i].txtcolContractNumber + "|";
              str += vsfData[i].txtcolFOrB + "|";
              str += vsfData[i].txtcolGrpShareLbs + "|A|";
              str += vsfData[i].txtcolPrice + "|";
              str += vsfData[i].txtcolSeed + "|";
              str += vsfData[i].txtcolContType + "|";
              str +=
                (vsfData[i].col27 === undefined ? 0 : vsfData[i].col27) + "|";
              str += vsfData[i].col28 === undefined ? 0 : vsfData[i].col28;
              lstNewContractLine.push(str);
            }
          }
        }
      } else {
        let data47 = vsfData[i].txtcolGrpShareLbs == ""? undefined:vsfData[i].txtcolGrpShareLbs
        if (isNaN(data47) == false) {
          let str = vsfData[i].txtcolContractNumber + "|";
          str += vsfData[i].txtcolFOrB + "|";
          str += vsfData[i].txtcolGrpShareLbs + "|A|";
          str += vsfData[i].txtcolPrice + "|";
          str += vsfData[i].txtcolSeed + "|";
          str += vsfData[i].txtcolContType + "|";
          str += (vsfData[i].col27 === undefined ? 0 : vsfData[i].col27) + "|";
          str += vsfData[i].col28 === undefined ? 0 : vsfData[i].col28;
          lstNewContractLine.push(str);
        }
      }
    }
    let mbtent_cont_appl_ind = getData(thisObj, "mbtent_cont_appl_ind");
    if (mbtent_cont_appl_ind != "I" && mbtent_cont_appl_ind != "O") {
      let txtPrice = getValue(thisObj, "txtPrice");
      let txtLbsToApply = getValue(thisObj, "txtLbsToApply");
      if (isNaN(txtPrice) == false) {
        if (isNaN(txtLbsToApply) == false) {
          lstNewContractLine.push("SPOT||" + txtLbsToApply + "|A|" + txtPrice);
        } else {
          lstNewContractLine.push("SPOT||" + 0 + "|A|" + txtPrice);
        }
      }
      let vsfData1 = getValue(thisObj, "gridSeedGrowerApplications");
      for (let i = 0; i < vsfData1.length; i++) {
        if (vsfData1[i].txtcolContractNumberSGA != "") {
          if (isNaN(vsfData1[i].txtcolPoundsToApplySGA) == false) {
            if (vsfData1[i].txtcolPoundsToApplySGA > 0) {
              let str = vsfData1[i].txtcolContractNumberSGA + "|SEED|";
              str += vsfData1[i].txtcolPoundsToApplySGA + "|S|";
              str += vsfData1[i].txtcolPriceSGA + "|A|";
              str += vsfData1[i].txtcolPoundsToApplySGA + "|";
              lstNewContractLine.push(str);
            }
          }
        }
      }
    }
    setData(thisObj, "lstNewContractLine", lstNewContractLine);
    bFillContractListflag = true;
    return bFillContractListflag;
  }

  //Code for bFillPounds
  const bFillPounds = async () => {
    let bFillPounds = true;
    let lblBuyPtIdValue = getData(thisObj, "lblBuyPtIdValue");
    let lblWRNumberValue = getData(thisObj, "lblWRNumberValue");
    let Mode = getData(thisObj, "Mode");
    let result2 = await WarehouseReceiptService.RetrieveWarehouseContractApplicationDetails(
      compId,
      cropYear,
      lblBuyPtIdValue,
      lblWRNumberValue
    );
    let cont_num = result2[0].contract_num;
    let lblDMAVendorValue = getData(thisObj, "lblDMAVendorValue");
    if (lblDMAVendorValue != "") {
      if (result2.length != 0) {
        let response2 = await ContractManagementService.RetrieveContractDetails(
          cont_num,
          "PN1010"
        );
        if (
          response2[0].contract_vendor_splits[0].split_vendor !=
          lblDMAVendorValue
        ) {
          alert(
            "This warehouse receipt contains 1007s with tentative applications and cannot be processed with a CMA vendor." &
              vbCrLf &
              "Please correct the applications or remove the CMA vendor."
          );
          bFillPounds = false;
          return;
        } else {
          bFillPounds = false;
          return;
        }
      }
    }
    if (result2.length != 0) {
      if (result2[0].pricing_line == 1) {
        let vsfData = getValue(thisObj, "gridContractApplications");
        for (var i = 0; i < vsfData.length; i++) {
          let contractNumber = vsfData[i].txtcolContractNumber;
          let cont_num = result2[i].contract_num;
          if (contractNumber == cont_num && vsfData[i].txtcolFOrB == "Firm") {
            vsfData[i].txtcolLbsToApply = result2[i].tent_lbs_adj;
            setValue(thisObj,'gridContractApplications',vsfData)
            
              _kaledo.thisObj.setState(current => ({
                ...current,
                gridContractApplications: {
                  ...current.gridContractApplications,
                  toggleRender: !state.gridContractApplications.toggleRender
                }
              }))
              hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
              hideColumn(thisObj, "gridContractApplications", "txtcolGrpSharePct");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
              hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
            
            //vsfData_CellChanged
            if (!isNaN(vsfData[i].txtcolLbsToApply) == false) {
              vsfData[i].txtcolLbsToApply = "";
              vsfData[i].txtcolOrder = "";
            }
            else{
              if(vsfData[i].txtcolLbsToApply == "0"){
                vsfData[i].txtcolLbsToApply = "";
                vsfData[i].txtcolOrder = "";   
              }
            }
            if(vsfData[i].txtcolGrouping == ""){
              if(Mode == "TENTATIVE_APPLICATION"){
                vsfData[i].txtcolTentApplied = (vsfData[i].col23 ==""? 0:vsfData[i].col23)
                vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied  + vsfData[i].txtcolTentApplied 
                vsfData[i].txtcolOpenBalance = vsfData[i].txtcolContractLbs  - vsfData[i].txtcolTotalApplication 
              }
              else{
                vsfData[i].txtcolApplied  = (vsfData[i].col22 ==""? 0:vsfData[i].col22)
                vsfData[i].txtcolSeedOpen = vsfData[i].txtcolContractLbs - vsfData[i].txtcolApplied
                vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied + vsfData[i].txtcolTentApplied 
                vsfData[i].txtcolOpenBalance = vsfData[i].txtcolContractLbs - vsfData[i].txtcolTotalApplication
              }
            }
            else{
              let sPounds = (vsfData[i].txtcolLbsToApply ==""? 0:vsfData[i].txtcolLbsToApply)
              let Row = Row + 1
              if(vsfData[i].txtcolGrpSharePct != ""){
                if(sPounds = "0"){
                  vsfData[i].txtcolGrpShareLbs == ""
                }
                if(Mode = "TENTATIVE_APPLICATION"){
                 vsfData[i].txtcolGrpShareLbs= ""
                 vsfData[i].txtcolTentApplied = (vsfData[i].col23 ==""? 0: vsfData[i].col23)
                 vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied  + vsfData[i].txtcolTentApplied 
                 vsfData[i].txtcolOpenBalance= vsfData[i].txtcolContractLbs  - vsfData[i].txtcolTotalApplication 
                }
                else{
                  vsfData[i].txtcolGrpShareLbs = ""
                  vsfData[i].txtcolApplied = (vsfData[i].col22 ==""? 0:vsfData[i].col22)
                  vsfData[i].txtcolSeedOpen = vsfData[i].txtcolContractLbs - vsfData[i].txtcolApplied
                  vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied + vsfData[i].txtcolTentApplied 
                  vsfData[i].txtcolOpenBalance = vsfData[i].txtcolContractLbs - vsfData[i].txtcolTotalApplication
                }
                if(Row < gridData.length - 1){
                  Row = Row + 1
                }
                else{
                  return;
                }
              }
            }
            ComputeOrderedValues()
            //=========================================================================
            if (vsfData[i].txtcolGrpSharePct != "") {
              if (
                [
                  mbtent_cont_appl_ind === "O" || mbtent_cont_appl_ind === "I",
                ] &&
                Mode === "PURCHASE"
              ) {
                vsfData[i].txtcolGrpShareLbs == vsfData[i].txtcolLbsToApply;
              } else {
                vsfData[i].txtcolGrpShareLbs ==
                  vsfData[i].txtcolLbsToApply *
                    (vsfData[i].txtcolGrpSharePct * 0.1);
              }
            }
          }
        }
      } else {
        let vsfData = getValue(thisObj, "gridContractApplications");
        for (let i = 0; i < vsfData.length; i++) {
          let contractNumber = vsfData[i].txtcolContractNumber;
          let cont_num = result2[i].contract_num;
          if (contractNumber == cont_num && vsfData[i].txtcolFOrB == "Basis") {
            vsfData[i].txtcolLbsToApply = result2[i].tent_lbs_adj;
            setValue(thisObj,'gridContractApplications',vsfData)
            
            _kaledo.thisObj.setState(current => ({
              ...current,
              gridContractApplications: {
                ...current.gridContractApplications,
                toggleRender: !state.gridContractApplications.toggleRender
              }
            }))
          hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
          hideColumn(thisObj, "gridContractApplications", "txtcolGrpSharePct");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
          hideColumn(thisObj, "gridContractApplications", "txtcolGrpShareLbs");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
          hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
            
            //vsfData_CellChanged
            if (!isNaN(vsfData[i].txtcolLbsToApply) == false) {
              vsfData[i].txtcolLbsToApply = "";
              vsfData[i].txtcolOrder = "";
            }
            else{
              if(vsfData[i].txtcolLbsToApply == "0"){
                vsfData[i].txtcolLbsToApply = "";
                vsfData[i].txtcolOrder = "";   
              }
            }
            if(vsfData[i].txtcolGrouping == ""){
              if(Mode == "TENTATIVE_APPLICATION"){
                vsfData[i].txtcolTentApplied = (vsfData[i].col23 ==""? 0:vsfData[i].col23)
                vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied  + vsfData[i].txtcolTentApplied 
                vsfData[i].txtcolOpenBalance = vsfData[i].txtcolContractLbs  - vsfData[i].txtcolTotalApplication 
              }
              else{
                vsfData[i].txtcolApplied  = (vsfData[i].col22 ==""? 0:vsfData[i].col22)
                vsfData[i].txtcolSeedOpen = vsfData[i].txtcolContractLbs - vsfData[i].txtcolApplied
                vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied + vsfData[i].txtcolTentApplied 
                vsfData[i].txtcolOpenBalance = vsfData[i].txtcolContractLbs - vsfData[i].txtcolTotalApplication
              }
            }
            else{
              let sPounds = (vsfData[i].txtcolLbsToApply ==""? 0:vsfData[i].txtcolLbsToApply)
              let Row = Row + 1
              if(vsfData[i].txtcolGrpSharePct != ""){
                if(sPounds = "0"){
                  vsfData[i].txtcolGrpShareLbs == ""
                }
                if(Mode = "TENTATIVE_APPLICATION"){
                 vsfData[i].txtcolGrpShareLbs= ""
                 vsfData[i].txtcolTentApplied = (vsfData[i].col23 ==""? 0: vsfData[i].col23)
                 vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied  + vsfData[i].txtcolTentApplied 
                 vsfData[i].txtcolOpenBalance= vsfData[i].txtcolContractLbs  - vsfData[i].txtcolTotalApplication 
                }
                else{
                  vsfData[i].txtcolGrpShareLbs = ""
                  vsfData[i].txtcolApplied = (vsfData[i].col22 ==""? 0:vsfData[i].col22)
                  vsfData[i].txtcolSeedOpen = vsfData[i].txtcolContractLbs - vsfData[i].txtcolApplied
                  vsfData[i].txtcolTotalApplication = vsfData[i].txtcolApplied + vsfData[i].txtcolTentApplied 
                  vsfData[i].txtcolOpenBalance = vsfData[i].txtcolContractLbs - vsfData[i].txtcolTotalApplication
                }
                if(Row < gridData.length - 1){
                  Row = Row + 1
                }
                else{
                  return;
                }
              }
            }
            ComputeOrderedValues()
            //=========================================================================
            if (vsfData[i].txtcolGrpSharePct != "") {
              if (
                [
                  mbtent_cont_appl_ind === "O" || mbtent_cont_appl_ind === "I",
                ] &&
                Mode === "PURCHASE"
              ) {
                vsfData[i].txtcolGrpShareLbs == vsfData[i].txtcolLbsToApply;
              } else {
                vsfData[i].txtcolGrpShareLbs ==
                  vsfData[i].txtcolLbsToApply *
                    (vsfData[i].txtcolGrpSharePct * 0.1);
              }
            }
          }
        }
      }
    }
  };

  //Code for Compute Ordered Values
  const ComputeOrderedValues = async() => {
    let tempArr = [];
    let rowsData =
      getData(_kaledo.thisObj, "gridContractApplications") ||
      getValue(_kaledo.thisObj, "gridContractApplications");
    let sAgreeNumTemp;
    let sOrderTemp;
    let sPoundsTemp;
    let sOpenTemp;
    let sRowTemp;
    let xxzz;
    let nHoldRowIndex;
    let nContractCount = 0;
    let nAvailablePounds =getValue(thisObj, "txtPounds") || getData(thisObj, "txtPounds")
    let nRowIndex = 1;
    let msMode = getData(thisObj, "Mode");

    for (let i = 0; i < rowsData.length; i++) {
      // let value20 = getGridValue(_kaledo.thisObj, 'gridContractApplications', 'txtcolLbsToApply', i)
      let value20 = rowsData[i].txtcolLbsToApply
      if(value20 == ""){
        rowsData[i].txtcolLbsToApply = ""
      }
      nHoldRowIndex = nRowIndex;
      let data30 =
        rowsData[i].txtcolGrouping === undefined
          ? ""
          : rowsData[i].txtcolGrouping;
      let eleme = {};
      if (rowsData[i].txtcolLbsToApply != "") {
        if (rowsData[i].txtcolOrder != "") {
          if (data30 != "") {
            let nGrpIndex = nRowIndex;
            nRowIndex = nRowIndex + 1;

            for (let i = 0; i < rowsData.length; i++) {
              const eleme = {
                contract_num: rowsData[i].txtcolContractNumber,
                order_num: getGridValue(
                  thisObj,
                  "gridContractApplications",
                  "txtcolOrder",
                  i
                ),
              };
              if (
                isNaN(
                  getGridValue(
                    thisObj,
                    "gridContractApplications",
                    "txtcolLbsToApply",
                    i
                  )
                ) == false
              ) {
                eleme.lbsToApply = rowsData[i].txtcolLbsToApply
              } else {
                if (nAvailablePounds < 0) {
                  nAvailablePounds = 0;
                }
                eleme.lbsToApply = parseInt(nAvailablePounds);
              }

              eleme.col25 = parseInt(rowsData[i].col25);
              eleme.rowIndex = nRowIndex;
              eleme.group = data30;
              eleme.colGrpSharePct = rowsData[i].txtcolGrpSharePct;
              eleme.seven =
                rowsData[i].txtcolLbsToApply *
                (rowsData[i].txtcolGrpSharePct * 0.01);
              eleme.grpIndex = nGrpIndex;
              eleme.nine = "";
              if (nRowIndex < rowsData.length - 1) {
                nRowIndex = nRowIndex + 1;
              } else {
                return;
              }

              if (nRowIndex <= rowsData.length - 1) {
                nRowIndex = nRowIndex - 1;
              }

              eleme.index = i;
            }
          } else {
            eleme.contract_num = rowsData[i].txtcolContractNumber;
            eleme.order_num = rowsData[i].txtcolOrder
            if (isNaN(rowsData[i].txtcolLbsToApply) == false) {
              eleme.lbsToApply = rowsData[i].txtcolLbsToApply;
            } else {
              if (nAvailablePounds < 0) {
                nAvailablePounds = 0;
              }
              eleme.lbsToApply = nAvailablePounds;
            }

              eleme.col25 = rowsData[i].col25;
              eleme.rowIndex = nRowIndex;
              eleme.grpIndex = "";
              eleme.nine = "";
              eleme.index = i;
            }
        } else {
          nAvailablePounds =
            nAvailablePounds -
             rowsData[i].txtcolLbsToApply
          if (nAvailablePounds < 0) {
            nAvailablePounds = 0;
          }
        }
      } else {
        if (rowsData[i].txtcolOrder != "") {
          // ReDim Preserve arrContracts(9, nContractCount)
          if (data30 != "") {
            nGrpIndex = nRowIndex;
            nRowIndex = nRowIndex + 1;
            for (let i = 0; i < rowsData.length; i++) {
              if (rowsData[i].txtcolGrpSharePct != "") {
                // ReDim Preserve arrContracts(9, nContractCount)
                eleme.contract_num = rowsData[i].txtcolContractNumber;
                eleme.order_num = rowsData[i].txtcolOrder;
                if (isNaN(rowsData[i].txtcolLbsToApply) == false) {
                  eleme.lbsToApply = rowsData[i].txtcolLbsToApply;
                } else {
                  if (nAvailablePounds < 0) {
                    nAvailablePounds = 0;
                  }
                  eleme.lbsToApply = nAvailablePounds;
                }
                eleme.col25 = rowsData[i].col25;
                eleme.rowIndex = nRowIndex;
                eleme.group = data30;
                eleme.colGrpSharePct = rowsData[i].txtcolGrpSharePct;
                eleme.seven =
                  rowsData[i].txtcolLbsToApply *
                  (rowsData[i].txtcolGrpSharePct * 0.01);
                eleme.grpIndex = nGrpIndex;
                eleme.nine = "";
              }
            }
          } else {
            eleme.contract_num = rowsData[i].txtcolContractNumber;
            eleme.order_num = rowsData[i].txtcolOrder;
            if (isNaN(rowsData[i].txtcolLbsToApply)) {
              eleme.lbsToApply = rowsData[i].txtcolLbsToApply;
            } else {
              if (nAvailablePounds < 0) {
                nAvailablePounds = 0;
              }
              eleme.lbsToApply = nAvailablePounds;

              eleme.col25 = rowsData[i].col25;
              eleme.rowIndex = nRowIndex;
              eleme.grpIndex = "";
              eleme.nine = "";
              nContractCount = nContractCount + 1;
            }
          }
        }
        eleme.index = i;
      }
      if (Object.keys(eleme).length != 0) tempArr.push(eleme);
      let data27 =
        (mbtent_cont_appl_ind == "O" || mbtent_cont_appl_ind == "I") &&
        msMode == "PURCHASE";
      if (!data27) {
        if (
          rowsData[i].txtcolOrder == "" ||
          rowsData[i].txtcolLbsToApply == "" ||
          rowsData[i].txtcolGrpSharePct == ""
        ) {
          if (nHoldRowIndex < nRowIndex) {
            for (let i = nHoldRowIndex; i < nRowIndex; i++) {
              rowsData[i].txtcolGrpShareLbs = "";
            }
          } else {
            rowsData[i].txtcolGrpShareLbs = "";
          }
        }
      }
      nRowIndex = nRowIndex + 1;
    }
    let data31 =
      getValue(thisObj, "txtOrder") == undefined
        ? ""
        : getValue(thisObj, "txtOrder");
    if (data31 != "") {
      //ReDim Preserve arrContracts(9, nContractCount)
      eleme.contract_num = "SPOT";
      eleme.order_num = data31;
      let data32 =
        getValue(thisObj, "txtLbsToApply") == undefined
          ? ""
          : getValue(thisObj, "txtLbsToApply");
      setData(thisObj, "data32", data32);
      if (isNaN(data32) == false) {
        eleme.lbsToApply = data32;
      } else {
        if (nAvailablePounds < 0) {
          nAvailablePounds = 0;
        }
        eleme.lbsToApply = nAvailablePounds;
      }
      eleme.col25 = getValue(thisObj, "txtPrice");
    } 
    else {
      let data33 =
        getValue(thisObj, "txtLbsToApply") == undefined
          ? ""
          : getValue(thisObj, "txtLbsToApply");
      if (data33 != "") {
        nAvailablePounds = nAvailablePounds - data33;
        if (nAvailablePounds < 0) {
          nAvailablePounds = 0;
        }
      }
    }
   
    if (tempArr.length > 1){
      for (let i = 0; i < tempArr.length - 1; i++) {
        if (tempArr[i].order_num > tempArr[i + 1].order_num) {
          sContractNumTemp = tempArr[i + 1].contract_num
          sOrderTemp = tempArr[i + 1].order_num
          sPoundsTemp =  tempArr[i + 1].lbsToApply
          sOpenTemp = tempArr[i + 1].col25
          sRowTemp = tempArr[i + 1].rowIndex
          sGrpNumTemp = tempArr[i + 1].group
          sGrpShareTemp = tempArr[i + 1].colGrpSharePct
          sGrpPoundsTemp = tempArr[i + 1].seven
          sGrpRowTemp = tempArr[i + 1].grpIndex
          sChanged = tempArr[i + 1].nine
          
          tempArr[i + 1].contract_num = tempArr[i].contract_num
          tempArr[i + 1].order_num = tempArr[i].order_num
          tempArr[i + 1].lbsToApply = tempArr[i].lbsToApply
          tempArr[i + 1].col25 = tempArr[i].col25
          tempArr[i + 1].rowIndex = tempArr[i].rowIndex
          tempArr[i + 1].group = tempArr[i].group
          tempArr[i + 1].colGrpSharePct =  tempArr[i].colGrpSharePct
          tempArr[i + 1].seven = tempArr[i].seven
          tempArr[i + 1].grpIndex =  tempArr[i].grpIndex
          tempArr[i + 1].nine = tempArr[i].nine
  
          tempArr[i].contract_num = sContractNumTemp
          tempArr[i].order_num = sOrderTemp
          tempArr[i].lbsToApply = sPoundsTemp
          tempArr[i].col25 = sOpenTemp
          tempArr[i].rowIndex =  sRowTemp
          tempArr[i].group = sGrpNumTemp
          tempArr[i].colGrpSharePct = sGrpShareTemp
          tempArr[i].seven = sGrpPoundsTemp
          tempArr[i].grpIndex = sGrpRowTemp
          tempArr[i].nine = sChanged
        }
      }
    }
    let bNewGroup = false
    let bNeedsAdjust = false
    let bAdjusted = false
    
    if (tempArr.length > 0){

        for (let i = 0; i < tempArr.length; i++) {
          let data35 = tempArr[i].contract_num === undefined? "":tempArr[i].contract_num 
            tempArr[i].nine  = "N"
            if (data35 != "") {
                if (data35 == "SPOT"){
                    if(tempArr[i].lbsToApply <= nAvailablePounds) {
                        if(data31 != tempArr[i].lbsToApply){
                            if((tempArr[i].lbsToApply) == 0){
                                setValue(thisObj,'txtLbsToApply','')
                            }
                            else{
                                setValue(thisObj,'txtLbsToApply',tempArr[i].lbsToApply)
                            }
                            // ontxtcolOrderBlur(xxzz, 'txtcolLbsToApply', tempArr[i].index)
                        }
                        nAvailablePounds = nAvailablePounds - parseInt(tempArr[i].lbsToApply)
                    }
                    else{
                        if(data31 != nAvailablePounds){
                            if(nAvailablePounds == 0){
                                setValue(thisObj,'txtLbsToApply','')
                            }
                            else{
                                setValue(thisObj,'txtLbsToApply',nAvailablePounds)
                            }
                            // ontxtcolOrderBlur(xxzz, 'txtcolLbsToApply', tempArr[i].index)
                        }
                        nAvailablePounds = nAvailablePounds - nAvailablePounds
                    }
                }
                else{   
                  if(tempArr[i].grpIndex != ""){

                    if(sHoldGrpNum != tempArr[i].group){
                        bNewGroup = true
                        bAdjusted = false
                    }
                    else{
                        bNewGroup = false
                    }
                    
                    sHoldGrpNum = tempArr[i].group
                    let nArrPounds = tempArr[i].seven
                    let nVsfPounds = rowsData[i].txtcolLbsToApply
                    let nVsfGrpPounds = rowsData[i].txtcolGrpShareLbs
                    
                    if(bNewGroup == true){
                        let nTempIndex = nRowIndex
                        nArrGrpPounds = 0
                        for (let i = 0; i < tempArr.length; i++) {
                            if(tempArr[i].group = sHoldGrpNum){
                                nArrGrpPounds = nArrGrpPounds +  tempArr[i].col25
                            }
                        }
                    
                        let nRowLbsLimit = 0
                        let nRowLbsAmount = 0
                        let bApplyByPct = true
                        if(nArrGrpPounds > nAvailablePounds){
                            nRowLbsLimit = nAvailablePounds
                        }
                        else{
                            nRowLbsLimit = nArrGrpPounds
                        }
                        if(nVsfPounds > 0){
                            if(nVsfPounds > nRowLbsLimit){
                                nRowLbsAmount = nRowLbsLimit
                            }
                            else{
                                nRowLbsAmount = nVsfPounds
                            }
                        }
                        else{
                            nRowLbsAmount = nRowLbsLimit
                        }
                    //     For n = arrContracts(8, nRowIndex) + 1 To vsfData.Rows - 1
                    //         If IsNumeric(vsfData.TextMatrix(n, 10)) Then
                    //             nWithoutDecimal = nRowLbsAmount * (vsfData.TextMatrix(n, 10) * 0.01)
                    //             If InStrB(1, nWithoutDecimal, ".") Then                                                                                                                  'if decimal amount found
                    //                 nWithoutDecimal = Left(nWithoutDecimal, InStr(1, nWithoutDecimal, ".") - 1)    'Remove the decimal
                    //             End If
                    //             If CLng(vsfData.TextMatrix(n, 20)) < CLng(nWithoutDecimal) Or (nWithoutDecimal = 0) Then
                    //                 bApplyByPct = False
                    //                 Exit For
                    //             End If
                    //         Else
                    //             Exit For
                    //         End If
                    //      Next
                    // End If
                                        
                    if(bApplyByPct == true){
                        if(nRowLbsAmount > 0){
                            rowsData[i].txtcolLbsToApply = nRowLbsAmount
                            rowsData[i].txtcolGrpShareLbs = nRowLbsAmount * (rowsData[i].txtcolGrpSharePct * 0.01)
                            // if InStrB(1, vsfData.TextMatrix(arrContracts(4, nRowIndex), 11), ".") Then                                                                                                                  'if decimal amount found
                            //     vsfData.TextMatrix(arrContracts(4, nRowIndex), 11) = Left(vsfData.TextMatrix(arrContracts(4, nRowIndex), 11), InStr(1, vsfData.TextMatrix(arrContracts(4, nRowIndex), 11), ".") - 1)    'Remove the decimal
                            //     bNeedsAdjust = true                                                                                                                                                                     'flag for adjustment
                            // }
                        }
                        else{
                            rowsData[i].txtcolLbsToApply = ""
                            rowsData[i].txtcolGrpShareLbs = ""
                        }
                        tempArr[i].nine = "Y"
                    }
                    else{
                        if(nRowLbsAmount > 0){
                            rowsData[i].txtcolLbsToApply = nRowLbsAmount
                            rowsData[i].txtcolGrpShareLbs = tempArr[i].col25
                        }
                        else{
                            rowsData[i].txtcolLbsToApply = ""
                            rowsData[i].txtcolGrpShareLbs = ""
                        }
                        tempArr[i].nine = "Y"
                        if(bFilling){
                            if(isNaN(rowsData[i].txtcolOrder) &&  (!isNaN(rowsData[i].txtcolLbsToApply))){
                                rowsData[i].txtcolOrder = ""
                            }
                        }
                    }
                     
                    if(isNaN(rowsData[i].txtcolGrpShareLbs) == false){
                        nAvailablePounds = nAvailablePounds - (rowsData[i].txtcolGrpShareLbs)
                    }
                    }
                  }
                  else{
                    let bNewGroup = false
                    let bAdjusted = false
                    let nArrPounds = tempArr[i].lbsToApply
                    let nVsfPounds = parseInt(rowsData[i].txtcolLbsToApply == "" ? 0:rowsData[i].txtcolLbsToApply)
                    let nVsfGrpPounds = 0
                    
                    let nRowLbsLimit = 0
                    let nRowLbsAmount = 0
                    if(tempArr[i].col25 > nAvailablePounds){
                        nRowLbsLimit = parseInt(nAvailablePounds)
                    }
                    else{
                        nRowLbsLimit = parseInt(tempArr[i].col25)
                    }
                    if(nVsfPounds > 0){
                        if(nVsfPounds > nRowLbsLimit){
                            nRowLbsAmount = nRowLbsLimit
                        }
                        else{
                            nRowLbsAmount = nVsfPounds
                        }
                    }
                    else{
                        nRowLbsAmount = nRowLbsLimit
                    }
                    if(nRowLbsAmount > 0){
                        rowsData[i].txtcolLbsToApply = nRowLbsAmount
                    }
                    else{
                        if(bFilling){
                            rowsData[i].txtcolOrder = ""
                        }
                        rowsData[i].txtcolLbsToApply = ""
                    }
                    tempArr[i].nine = "Y"
                                    
                    if(isNaN(rowsData[i].txtcolLbsToApply) == false){
                        nAvailablePounds = nAvailablePounds - rowsData[i].txtcolLbsToApply
                    }
                                   
                    if((bNeedsAdjust) && !(bAdjusted) && !(bNewGroup)){   
                    nAvailablePounds = nAvailablePounds - 1
                    bAdjusted = true
                    }
                    bNeedsAdjust = false
                    
                  }
                }
            }
        }        
    }
    // //Drop decimal
    // For nRowIndex = 0 To vsfData.Rows - 1
    //     If IsNumeric(vsfData.TextMatrix(nRowIndex, 11)) Then
    //         If InStrB(1, vsfData.TextMatrix(nRowIndex, 11), ".") Then
    //             vsfData.TextMatrix(nRowIndex, 11) = Left(vsfData.TextMatrix(nRowIndex, 11), InStr(1, vsfData.TextMatrix(nRowIndex, 11), ".") - 1)
    //         End If
    //     End If
    // Next nRowIndex

    //Adjust Pounds
    for(let i = 0;i < rowsData.length; i++){
      if(rowsData[i].txtcolOrder !=""){
      if(isNaN(rowsData[i].txtcolGrouping) && isNaN(rowsData[i].txtcolOrder) && isNaN(rowsData[i].txtcolLbsToApply)){
          let nHoldGrpContracts = 1
          let nHoldGrpPounds = 0
          let nHoldDiff = 0
          if(isNaN(rowsData[i].txtcolGrpShareLbs)){
              nHoldGrpPounds = nHoldGrpPounds + rowsData[i].txtcolGrpShareLbs
              nHoldGrpContracts = nHoldGrpContracts + 1
              if(nRowIndex + nHoldGrpContracts >= rowsData.length){
                  return;
              }
          }
          nHoldGrpContracts = nHoldGrpContracts - 1
          nHoldDiff = rowsData[i].txtcolLbsToApply - nHoldGrpPounds
          if(nHoldDiff > 0 && nHoldGrpPounds > 0){      
              for(let i = 0; i<nGoldDiff; i++){
                  if(isNaN(rowsData[i].txtcolGrpShareLbs)){  
                      if(i != nHoldGrpContracts){
                          rowsData[i].txtcolGrpShareLbs = rowsData[i].txtcolGrpShareLbs + 1
                      }
                      else{
                          rowsData[i].txtcolGrpShareLbs = rowsData[i].txtcolGrpShareLbs + (nHoldDiff - i) + 1
                          return;
                      }
                  }
              }    
          }
}
 
if (tempArr.length > 0){
  let data41
      if(msMode == "TENTATIVE_APPLICATION"){
          if(tempArr[i].nine == "Y"){
              if(tempArr[i].grpIndex == ""){
                  let data51 = rowsData[i].col23 == "" ? 0:rowsData[i].col23
                  let data52 = rowsData[i].txtcolLbsToApply == "" ?0:rowsData[i].txtcolLbsToApply
                  let data40 = Number(data51) + Number(data52)
                  rowsData[i].txtcolTentApplied = data40
              }
              else{
                  data41 = rowsData[i].col23 == "" ? 0:rowsData[i].col23 + rowsData[i].txtcolGrpShareLbs == "" ?0:rowsData[i].txtcolGrpShareLbs
                  rowsData[i].txtcolTentApplied = data41
              }
              rowsData[i].txtcolTotalApplication  = parseInt(rowsData[i].txtcolApplied) + parseInt(rowsData[i].txtcolTentApplied)
              rowsData[i].txtcolOpenBalance = rowsData[i].txtcolContractLbs - rowsData[i].txtcolTotalApplication
              setValue(thisObj,'gridContractApplications',rowsData)
              
              _kaledo.thisObj.setState(current => ({
                ...current,
                gridContractApplications: {
                  ...current.gridContractApplications,
                  toggleRender: !state.gridContractApplications.toggleRender
                }
              }))
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedOpen");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedTBD");
              hideColumn(thisObj, "gridContractApplications", "txtcolSeedCont");
          }
        }
      else{
          if(tempArr[i].nine == "Y"){
              if(tempArr[i].grpIndex == ""){
                  let data42 = rowsData[i].col22 == "" ? 0:rowsData[i].col22 + rowsData[i].txtcolLbsToApply == "" ?0:rowsData[i].txtcolLbsToApply
                  rowsData[i].txtcolApplied = data42
              }
              else{
                  let data43 = rowsData[i].col22 == "" ? 0:rowsData[i].col22 + rowsData[i].txtcolGrpShareLbs == "" ?0:rowsData[i].txtcolGrpShareLbs
                  rowsData[i].txtcolApplied = data43
              }
              rowsData[i].txtcolSeedOpen = rowsData[i].txtcolContractLbs - rowsData[i].txtcolApplied
              rowsData[i].txtcolTotalApplication = rowsData[i].txtcolApplied + rowsData[i].txtcolTentApplied
              rowsData[i].txtcolOpenBalance = rowsData[i].txtcolContractLbs  - rowsData[i].txtcolTotalApplication
          }
      }
    
}
}
    }

  }

  //Code for ontxtOrderBlur
   const ontxtOrderBlur =()=>{
    ComputeOrderedValues()
  }
  thisObj.ontxtOrderBlur = ontxtOrderBlur;

  //Code for ontxtLbsToApplyBlur
   const ontxtLbsToApplyBlur =()=>{
    ComputeOrderedValues()
  }
  thisObj.ontxtLbsToApplyBlur = ontxtLbsToApplyBlur;

  //Code for ontxtOverideFarmNum
  const ontxtOverideFarmNumBlur =()=>{
    if(isNaN(getValue(thisObj,'txtOverideFarmNum') == false)){
        let data77 = getValue(thisObj,'txtOverideFarmNum')
        let txtOverideFarmNum = '0'.repeat((7 - (data77.length))) + data77
    }
    ComputeOrderedValues()
  }
  thisObj.ontxtOverideFarmNumBlur = ontxtOverideFarmNumBlur;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_WhouseApplications*/}

              {/* END_USER_CODE-USER_BEFORE_WhouseApplications*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWhouseApplications*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWhouseApplications*/}

              <GroupBoxWidget
                conf={state.grpbxWhouseApplications}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceiptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceiptNum*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWarehouseReceiptNum}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWarehouseReceiptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtWarehouseReceiptNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUnloadLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUnloadLocation*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUnloadLocation}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUnloadLocation*/}

                  {/* END_USER_CODE-USER_AFTER_txtUnloadLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtObligationLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_txtObligationLocation*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtObligationLocation}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtObligationLocation*/}

                  {/* END_USER_CODE-USER_AFTER_txtObligationLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPeanutType}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPounds*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPounds*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPounds}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPounds*/}

                  {/* END_USER_CODE-USER_AFTER_txtPounds*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOverideFarmNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOverideFarmNum*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOverideFarmNum}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOverideFarmNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtOverideFarmNum*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContractApplication*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContractApplication*/}

                <GroupBoxWidget
                  conf={state.grpbxContractApplication}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblContractApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_lblContractApplications*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblContractApplications}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblContractApplications*/}

                  {/* END_USER_CODE-USER_AFTER_lblContractApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClearValues*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClearValues*/}

                  <ButtonWidget
                    conf={state.btnClearValues}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClearValues*/}

                  {/* END_USER_CODE-USER_AFTER_btnClearValues*/}
                  {/* START_USER_CODE-USER_BEFORE_gridContractApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_gridContractApplications*/}

                  <GridWidget
                    conf={state.gridContractApplications}
                    screenConf={state}
                    linkClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridContractApplications}
                    onEvent={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    refObject={thisObj}
                    key={state.gridContractApplications.toggleRender}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridContractApplications*/}

                  {/* END_USER_CODE-USER_AFTER_gridContractApplications*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContractApplication*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContractApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                <GroupBoxWidget
                  conf={state.grpbxSpotApplication}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSpotApplication*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSpotApplication*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSpotApplication}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSpotApplication*/}

                  {/* END_USER_CODE-USER_AFTER_lblSpotApplication*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOrder*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOrder*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOrder}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOrder*/}

                  {/* END_USER_CODE-USER_AFTER_txtOrder*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLbsToApply}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_AFTER_txtLbsToApply*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPrice*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPrice}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPrice*/}

                  {/* END_USER_CODE-USER_AFTER_txtPrice*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSpotApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSeedGroverApplications*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSeedGroverApplications*/}

                <GroupBoxWidget
                  conf={state.grpbxSeedGroverApplications}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSeedGrowerApplications*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSeedGrowerApplications}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_AFTER_lblSeedGrowerApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSeedLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSeedLbs*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeedLbs}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSeedLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtSeedLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_gridSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_gridSeedGrowerApplications*/}

                  <GridWidget
                    conf={state.gridSeedGrowerApplications}
                    screenConf={state}
                    linkClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridSeedGrowerApplications}
                    onEvent={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_AFTER_gridSeedGrowerApplications*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSeedGroverApplications*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSeedGroverApplications*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWhouseApplications*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWhouseApplications*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_WhouseApplications*/}

              {/* END_USER_CODE-USER_AFTER_WhouseApplications*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WhouseApplications);
